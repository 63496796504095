import './main.css';
import "./Authorization.css";
import { useEffect } from 'react';


function Authorization(props) {
    useEffect(() => {
        props.ws.webSocket.onmessage = (event) => {
            if (event.data !== 'auth failure'){
                window.localStorage.setItem('twitch_claims', JSON.stringify(parseJwt(window.location.hash)));
                window.localStorage.setItem('twitch_access_token', JSON.stringify(getAccessToken(window.location.hash)));
                window.location = window.location.origin;
            }
        };
        props.ws.webSocket.onopen = () => {
            let command = {
                type: 'auth',
                token: getAccessToken(window.location.hash),
                claims: parseJwt(window.location.hash)
            }
            props.ws.sendCommand(command);
        }
    }, []);

    if(!window.location.hash){
        window.location = window.location.origin;
    }
    return (
        <h1 id='loginText'>Logging you in...</h1>
    );
}

function getAccessToken(hash) {
    return hash.split('&')[0].replace("#", "").replace("access_token=", "");
}

function parseJwt(hash) {
    var base64Url = hash.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export default Authorization;