import React from 'react';
import './main.css';
import './InfoPage.css';

function InfoPage(props) {
    return (
        <div id='infoPageContainer'>
            <h1>
                Stream Interactor v2.1
            </h1>
            <h2>
                Programs I use:
            </h2>
            <ul>
                <li>
                    <h3>
                        {'>> '}<a href='https://github.com/obsproject/obs-studio'>obs-studio</a>
                    </h3>
                    <p>
                        Open source streaming and recording client.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://github.com/l3lackShark/gosumemory'>gosumemory</a> by l3lackShark
                    </h3>
                    <p>
                        Allows for in-game osu! overlays that count judgements, pp, etc.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://github.com/Friedchicken-42/KeyOverlay'>KeyOverlay</a> by Friedchicken42
                    </h3>
                    <p>
                        Displays a live replay of keypresses.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://github.com/yugecin/osukps'>osukps</a> by yugecin
                    </h3>
                    <p>
                        Displays kps and total keypresses.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://github.com/Piotrekol/StreamCompanion'>StreamCompanion</a> by Piotrekol
                    </h3>
                    <p>
                        Provides live updating data from the osu! client. Used to create the map info scene.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://streamlabs.com/desktop-widgets/stream-labels'>Stream Labels</a> by Streamlabs
                    </h3>
                    <p>
                        Provides live updating data from Twitch and Streamlabs events.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://github.com/baffler/Transparent-Twitch-Chat-Overlay'>Transparent Twitch Chat Overlay</a> by baffler
                    </h3>
                    <p>
                        Renders an configurable transparent window of any Twitch chat.
                    </p>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://www.speechchat.com/'>SpeechChat</a>
                    </h3>
                    <p>
                        Web-based customizable TTS for Twitch and Youtube chats.
                    </p>
                </li>
            </ul>
            <h2>
                Gear I use:
            </h2>
            <ul>
                <li>
                    <h3>
                        {'>> '}<a href='https://www.yunzii.com/products/yunzii-kc104-macaron-104-keys-rgb-mechanical-gaming-keyboard-with-pbt-keycap-full-nkro-pbt-and-detachable-usb-c-cable'>YUNZII KC104 Keyboard</a>
                    </h3> 
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://shop.panasonic.com/cameras-and-camcorders/cameras/point-and-shoot-cameras/dmc-fz1000'>Panasonic LUMIX FZ1000 Camera</a>
                    </h3>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://www.audio-technica.com/en-us/at2035'>Audio Technica AT2035 Condenser Microphone</a>
                    </h3>
                </li>
                <li>
                    <h3>
                        {'>> '}<a href='https://www.audio-technica.com/en-us/ath-m50x'>Audio Technica ATH-M50 Headphones (old style)</a>
                    </h3>
                </li>
            </ul>
        </div>
    );
}

export default InfoPage;