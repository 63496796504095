import homeIcon from './media/icons/home.svg';
import './ReturnButton.css'
import { Link } from 'react-router-dom';

function ReturnButton (props){
    return (
        <Link to='/'>
            <button id='returnButton'>
                <img src={homeIcon}/>
            </button>
        </Link>
    );
}

export default ReturnButton;
