import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import './index.css';
import App from './App';
import Authorization from './Authorization';
import Userpage from './Userpage';
import Requests from './Requests';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SiWebSocket from './SiWebSocket';
import Leaderboard from './Leaderboard';
import InfoPage from './InfoPage';

const ws = new SiWebSocket();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App ws={ws}/>} />
      <Route path="authorize" element={<Authorization ws={ws}/>} />
      <Route path='req' element={<Requests ws={ws}></Requests>}/>
      <Route path='user' element={<Userpage ws={ws}/>}>
        <Route path=':userId' element={<Userpage ws={ws}/>}/>
      </Route>
      <Route path='leaderboard/*' element={<Leaderboard ws={ws}/>}>
        <Route path=':statName' element={<Leaderboard ws={ws}/>}/>
      </Route>
      <Route path='info' element={<InfoPage/>}/>
    </Routes>
  </BrowserRouter>
);
