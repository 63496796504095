import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './main.css';
import './Userpage.css';
import DataLoader from './dataLoader';
import ReturnButton from './ReturnButton';
import { Link } from 'react-router-dom';

import BananasImage from './media/banana.png';
import McTokensImage from './media/token.png';
import CashImage from './media/cash.png';
import BitsImage from './media/bit.png';
import SubPointsImage from './media/sub-point.png';
import GoldenBananasImage from './media/golden-banana.png';
import audioIcon from './media/icons/audio.svg';
import searchIcon from './media/icons/search.svg';

var siWebSocket;

const lightColors = {
    legendary: "#ffd269",
    epic: "#e175ff",
    rare: "#8ad5f2",
    uncommon: "#a5fc97",
    common: "#dbdbdb",
    unique: '#03fcf0'
}

const darkColors = {
    legendary: "#753e0b",
    epic: "#5b0782",
    rare: "#183078",
    uncommon: "#21541c",
    common: "#595959",
    unique: '#00638a',
    buttonNeutral: '#2b2b2b',
    buttonRed: '#691222'
}

const colors = {
    legendary: "#e69e19",
    epic: "#8f23c2",
    rare: "#3773ac",
    uncommon: "#4CAF50",
    common: "#b6b6b6",
    unique: '#0ac4d1',
    buttonRed: '#b33737',
    buttonNeutral: '#545454',
    textGreen: '#1ebb1e',
    textRed: '#f53333'
}

const currencyImages = {
    Bananas: BananasImage,
    McTokens: McTokensImage,
    Cash: CashImage,
    Bits: BitsImage,
    SubPoints: SubPointsImage,
    GoldenBananas: GoldenBananasImage
};

const dataLoader = new DataLoader();
const items = {
    throwables: dataLoader.loadThrowables(),
    sounds: dataLoader.loadSounds(),
    greenscreens: dataLoader.loadGreenscreens(),
    videos: dataLoader.loadVideos(),
    scenes: dataLoader.loadScenes()
}

const generalStatsMap = [
    { serverName: 'LastSeen', text: 'Last Seen', detail: 'When this user was last seen in chat' },
    { serverName: 'FirstSeen', text: 'First Seen', detail: 'When this user was first seen in Overlay 2.0' },
    { serverName: 'MessagesSent', text: 'Messages Sent', detail: 'Amount of messages sent in Twitch chat', leaderboard: true },
    { serverName: 'ThrowableQuestsCompleted', text: 'Throwable Quests Completed', detail: 'Amount of items you\'ve delivered to Yoyopobot5', leaderboard: true },
    { serverName: 'TimesFirst', text: 'Times First', detail: 'Amount of times you\'ve claimed the \"First!\" reward', leaderboard: true },
    { serverName: 'RequestsMade', text: 'Requests Made', detail: 'Amount of requests you\'ve made', leaderboard: true },
    { serverName: 'RequestsCompleted', text: 'Requests Completed', detail: 'Amount of requests of yours Yoyoyopo5 has completed', leaderboard: true },
    { serverName: 'TimesRaided', text: 'Times Raided', detail: 'Amount of times you\'ve raided the stream' },
    { serverName: 'RaidersBrought', text: 'Raiders Brought', detail: 'Total amount of raiders you\'ve brought to streams' },
    { serverName: 'BugsFound', text: 'Bugs Found', detail: 'Amount of new overlay bugs you\'ve reported to Yoyoyopo5', leaderboard: true },
    { serverName: 'TweetsCreated', text: 'Tweets Created', detail: 'Amount of tweets you\'ve posted to the Yoyoyopo5 stream Twitter account', leaderboard: true },
    { serverName: 'RollCalls', text: 'Roll Calls', detail: 'Amount of times you\'ve participated in a roll call', leaderboard: true },
];

const rewardStatsMap = [
    { serverName: 'ItemsRolled', text: 'Items Rolled', detail: 'Amount of times you\'ve rolled for a new item', leaderboard: true },
    { serverName: 'ItemsSold', text: 'Items Sold', detail: 'Amount of items you\'ve sold', leaderboard: true },
    { serverName: 'ItemsThrown', text: 'Items Thrown', detail: 'Amount of items you\'ve thrown on stream', leaderboard: true },
    { serverName: 'BananaTreesPlanted', text: 'Banana Trees Planted', detail: 'Amount of banana trees you\'ve planted on stream', leaderboard: true },
    { serverName: 'SoundsPlayed', text: 'Sounds Played', detail: 'Amount of sounds you\'ve played on stream', leaderboard: true },
    { serverName: 'ScenesSwapped', text: 'Scenes Swapped', detail: 'Amount of times you\'ve changed stream scenes', leaderboard: true },
    { serverName: 'FireworksLaunched', text: 'Fireworks Launched', detail: 'Amount of fireworks you\'ve launched on stream', leaderboard: true },
    { serverName: 'SmallVideosPlayed', text: 'Videos Played', detail: 'Amount of videos you\'ve played on stream', leaderboard: true },
    { serverName: 'BigVideosPlayed', text: 'Scenes Played', detail: 'Amount of scenes you\'ve played on stream', leaderboard: true },
    { serverName: 'BigVideosStopped', text: 'Scenes Aborted', detail: 'Amount of scenes you\'ve cancelled on stream', leaderboard: true },
    { serverName: 'GreenScreensManifested', text: 'Events Manifested', detail: 'Amount of events you\'ve manifested on stream', leaderboard: true },
    { serverName: 'KeysPressed', text: 'Keys Pressed', detail: 'Amount of keypresses you\'ve sent to Yoyoyopo5\'s PC', leaderboard: true },
    { serverName: 'YoutubeVideosPlayed', text: 'YouTube Videos Played', detail: 'Amount of YouTube videos you\'ve played on stream', leaderboard: true },
    { serverName: 'RequestsBoosted', text: 'Requests Boosted', detail: 'Amount of requests you\'ve boosted through the queue', leaderboard: true}
]

const founderStatsMap = [
    { serverName: 'LegacyLevel', text: 'Founder Level', detail: 'The level you achieved in Overlay 1.0', leaderboard: true },
    { serverName: 'LegacyGoldenBananasFound', text: 'Founder Golden Bananas', detail: 'Amount of golden bananas you found in Overlay 1.0', leaderboard: true },
    { serverName: 'MonkeyFed', text: 'Monkey Fed', detail: 'Amount of bananas fed to Yoyopobot5', leaderboard: true },
    { serverName: 'LegacyMonkeyQuestXp', text: 'Legacy Monkey Quest XP', detail: 'Amount of XP Yoyopobot5 has given you for completing his quests in Overlay 1.0', leaderboard: true },
    { serverName: 'LegacyMonkeyQuestsCompleted', text: 'Legacy Monkey Quests Completed', detail: 'Amount of quests you\'ve completed for Yoyopobot5 in Overlay 1.0', leaderboard: true }
];

function generateKey(){
    return Math.floor(Math.random() * 100000);
}

function pascalToKebab(name) {
    return name.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
}

function UserSearchBar(props) {
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [lastResult, setLastResult] = useState([]);
    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            sendSearch(searchText);
        }, 600);
        return () => {
            clearTimeout(typingTimeout);
        }
    }, [searchText]);
    useEffect(() => {
        if (lastResult != props.results) {
            if (props.results != null)
            {
                setLastResult(props.results);
            }
            setIsLoadingSearch(false);
        }
    }, [props]);
    const sendSearch = (query) => {
        if (query.trim() === '') { return; }
        siWebSocket.sendCommand({
            type: 'user-search',
            query: query
        })
        setIsLoadingSearch(true);
    }
    const getResults = () => {
        if (isLoadingSearch || lastResult.length == 0) {
            return;
        }
        return (
            <div id='userSearchOutputContainer'>
                {lastResult.map(result => UserSearchResult(result))}
            </div>
        );
    }
    return (
        <div id='userSearchBarContainer'>
            <div id='userSearchBarInput'>
                    <span>
                        <img src={searchIcon}/>
                    </span>
                    <input id='userSearchBarInputBox' placeholder='type to search users' onChange={(e) => {setSearchText(e.target.value); setIsLoadingSearch(false)}}/>
                    <div className='loadingSpinner' style={{visibility: isLoadingSearch ? 'visible' : 'hidden'}}/>
            </div>
            {getResults()}
        </div>
    );
}

function UserSearchResult(props) {
    return (
        <div className='userSearchResultContainer'>
            <a href={window.location.origin + '/user/' + props.UserId}>{props.Username}</a>
        </div>
    );
}

function ToxicityMeterTick(props) {
    return (
        <div className='semicircleProgressBarTick' style={{transform: `rotate(${props.degrees}deg)`}}>
        </div>
    );
}

function ToxicityMeter(props) {
    const toxicity = props.toxicity;
    const ticks = [-90, -45, 0, 45, 90];
    const getColor = () => {
        const r = Math.min(toxicity / 0.5, 1) * 255;
        const g = Math.min((1 - (toxicity / 1)), 0.5) * 255;
        const b = 0;
        return `rgb(${r}, ${g}, ${b})`;
    }
    const getRotation = () => {
        //1 = 90deg, 0 = -90deg, 0.5 = 0deg
        const adjTox = toxicity - 0.5;
        return adjTox * 180;
    }
    const getConicBackground = () => {
        const rotation = getRotation();
        let startingDegrees = 0;
        let endingDegress = rotation;
        if (rotation < 0) {
            startingDegrees = 360 + rotation;
            endingDegress = 0;
        }
        const filledArea = `${getColor()} ${startingDegrees}deg ${endingDegress}deg`;
        const transparentArea = `#222 ${endingDegress}deg ${startingDegrees}deg`;
        if (rotation < 0){
            return `conic-gradient(at 50% 100%, ${transparentArea}, ${filledArea})`;
        }
        return `conic-gradient(at 50% 100%, ${filledArea}, ${transparentArea})`;
    }
    return (
        <div id='toxicityMeterContainer'>
            <div className='semicircleProgressBar' style={{background: getConicBackground()}}>
                <div className='semicircleProgressBarInner'></div>
                {ticks.map(tick => ToxicityMeterTick({ degrees: tick }))}
                <div className='semicircleProgressBarMainTick' style={{backgroundColor: getColor(), transform: `rotate(${getRotation()}deg)`}}></div>
            </div>
            <div id='toxicityMeterLabel'>
                <Link to={'/leaderboard/toxicity'}>{Math.round(Math.abs((props.toxicity - 0.5) * 2) * 100)}% </Link>
                <span>{((props.toxicity - 0.5) >= 0 ? "Toxic" : "Wholesome")}</span>
            </div>
        </div>
    );
}

function StatContainer (props) {
    if (props.stat.text === 'Last Seen' || props.stat.text === 'First Seen') {
        props.amount = props.amount.substring(0, 10);
    }
    const amount = () => {
        if (props.stat.leaderboard) {
            return (
                <Link to={'/leaderboard/stats/' + pascalToKebab(props.stat.serverName)}>{props.amount}</Link>
            );
        }
        return (
            <span>{props.amount}</span>
        );
    }
    return (
        <div key={props.key} style={{display: 'contents'}}>
            <div className='statTextContainer' style={{gridColumn: '1'}}>
                <span>{props.stat.text}</span>
                <div className='statDetailContainer'>
                    <span>{props.stat.detail}</span>
                </div>
            </div>
            <div style={{gridColumn: '2'}}>
                {amount()}
            </div>
        </div>
    );
}

function AchievementContainer (props) {
    return (
        <div className='achievementContainer' style={{backgroundColor: darkColors[props.Rarity.toLowerCase()]}}>
            <svg className='achievementImage' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill={colors[props.Rarity.toLowerCase()]} d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.834 9.686l-4.166.575 3.032 2.914-.74 4.139 3.708-1.982 3.708 1.983-.74-4.139 3.032-2.915-4.166-.575-1.834-3.784-1.834 3.784z"/>
            </svg>
            <div className='achievementTextBackground'/>
            <p className='achievementText'>{props.FriendlyName}</p>
            <div className='achievementDetailContainer' style={{backgroundColor: darkColors[props.Rarity.toLowerCase()]}}>
                <p>{props.Description}</p>
                <p>{props.TimeAchieved.substring(0, 10)}</p>
            </div>
        </div>
    );
}

function CurrencyContainer (props) {
    return (
        <div key={props.key} style={{display: 'contents'}}>
            <div className='currencyImageContainer' style={{gridColumn: '1'}}>
                <span><img src={props.image} className='currencyImage'></img></span>
                <div className='currencyStatsTooltip'>
                    <div style={{gridColumn: '1'}}>
                        <p>EARNED: </p>
                        <p>SPENT: </p>
                    </div>
                    <div style={{gridColumn: '2'}}>
                        <p>
                            <Link to={'/leaderboard/stats/' + pascalToKebab(props.name) + '-earned'}>{props.earned}</Link>
                        </p>
                        <p>
                            <Link to={'/leaderboard/stats/' + pascalToKebab(props.name) + '-spent'}>{props.spent}</Link>
                        </p>
                    </div>
                </div>
            </div>
            <div style={{gridColumn: '2'}}>
                <Link to={'/leaderboard/currencies/' + pascalToKebab(props.name)}>{props.amount}</Link>
            </div>
        </div>
    );
}

function ItemContainer(props) {
    const foundItem = items[props.item.Type.toLowerCase() + 's'].filter(item => item.name === props.item.Name)[0];
    if (foundItem == undefined) { return; }
    const getImageSource = () => {
        if (props.item.Type === 'Sound') { return audioIcon; }
        return foundItem.image;
    }
    return (
        <div key={props.key} className='seenItemContainer' style={{backgroundColor: darkColors[foundItem.rarity.toLowerCase()], borderColor: colors[foundItem.rarity.toLowerCase()]}}>
            <img className='seenItemImage' src={getImageSource()}></img>
            <p className='seenItemName'>{foundItem.friendlyName}</p>
            <div className='seenItemHoverable' style={{backgroundColor: darkColors[foundItem.rarity.toLowerCase()]}}>
                <p className='seenItemDate'>{props.time.substring(0, 10)}</p>
            </div>
        </div>
    );
}

function ItemsCount(props) {
    return (
        <div className='itemsCountContainer'>
            <p className='seenItemsAmount'>{props.seenItems.length} / {props.totalItems.length}</p>
            <div className='itemsCountHoverable'>
                <h1 className='itemsCountHoverableTitle'>{props.seenItems.length} / {props.totalItems.length}</h1>
                <div className='itemsCountHoverableSubContainer'>
                    <p className='itemsCountHoverableSubText' style={{color: lightColors['common']}}>{props.seenItems.filter(seenItem => seenItem.Item.Rarity === 'Common').length} / {props.totalItems.filter(item => item.rarity === 'Common').length}</p>
                    <p className='itemsCountHoverableSubText' style={{color: lightColors['uncommon']}}>{props.seenItems.filter(seenItem => seenItem.Item.Rarity === 'Uncommon').length} / {props.totalItems.filter(item => item.rarity === 'Uncommon').length}</p>
                    <p className='itemsCountHoverableSubText' style={{color: lightColors['rare']}}>{props.seenItems.filter(seenItem => seenItem.Item.Rarity === 'Rare').length} / {props.totalItems.filter(item => item.rarity === 'Rare').length}</p>
                    <p className='itemsCountHoverableSubText' style={{color: lightColors['epic']}}>{props.seenItems.filter(seenItem => seenItem.Item.Rarity === 'Epic').length} / {props.totalItems.filter(item => item.rarity === 'Epic').length}</p>
                    <p className='itemsCountHoverableSubText' style={{color: lightColors['legendary']}}>{props.seenItems.filter(seenItem => seenItem.Item.Rarity === 'Legendary').length} / {props.totalItems.filter(item => item.rarity === 'Legendary').length}</p>
                </div>
            </div>
        </div>
    );
}

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class Userpage extends React.Component {
    constructor(props) {
        super (props);
        this.state = {
            userId: this.props.params.userId,
            userNotFound: false,
            user: null,
            stats: []
        }
    }
    componentDidMount() {
        if (this.props.ws.webSocket.readyState) {
            this.webSocketConnected();
        }
        this.props.ws.webSocket.onopen = () => this.webSocketConnected();
        this.props.ws.webSocket.onerror = () => window.location = window.location.origin;
    }
    webSocketConnected() {
        siWebSocket = this.props.ws;
        siWebSocket.webSocket.onmessage = (event) => {
            const receivedCommand = JSON.parse(event.data);
            if (!('Type' in receivedCommand)){
                return;
            }
            if (receivedCommand.Type == 'error') {
                this.setState({ userNotFound: true, errorMessage: receivedCommand.Message });
                return;
            }
            if (receivedCommand.Type == 'user-data') {
                if (receivedCommand.UserDataType === 'User') {
                    this.setState({ user: receivedCommand.UserData });
                }
            }
            if (receivedCommand.Type == 'user-search-data') {
                this.setState({ userSearchOutput: receivedCommand.FoundUsers });
            }
        };
        this.getDataFromServer();
    }
    getDataFromServer() {
        const dataRequest = {
            type: 'user-data-request',
            userId: this.state.userId
        };
        siWebSocket.sendCommand(dataRequest);
    }
    buildStatContainers() {
        const generalStats = [];
        const rewardStats = [];
        const founderStats = [];
        const userStats = this.state.user.Stats;
        const statNames = Object.keys(userStats);
        for (let i = 0; i < statNames.length; i++){
            for (let j = 0; j < generalStatsMap.length; j++){
                if (statNames[i] === generalStatsMap[j].serverName){
                    generalStats.push({ key: generateKey(), stat: generalStatsMap[j], amount: userStats[statNames[i]] });
                }
            }
            for (let j = 0; j < rewardStatsMap.length; j++){
                if (statNames[i] === rewardStatsMap[j].serverName){
                    rewardStats.push({ key: generateKey(), stat: rewardStatsMap[j], amount: userStats[statNames[i]] });
                }
            }
            for (let j = 0; j < founderStatsMap.length; j++){
                if (statNames[i] === founderStatsMap[j].serverName){
                    founderStats.push({ key: generateKey(), stat: founderStatsMap[j], amount: userStats[statNames[i]] });
                }
            }
        }
        return (
            <div id='statsContainers'>
                <div className='statContainer general'>
                    <h1 className='statContainerHeading'>GENERAL STATS</h1>
                    {generalStats.map(stat => StatContainer(stat))}
                </div>
                <div className='statContainer reward'>
                    <h1 className='statContainerHeading'>REWARD STATS</h1>
                    {rewardStats.map(stat => StatContainer(stat))}
                </div>
                <div className='statContainer founder'>
                    <h1 className='statContainerHeading'>FOUNDER STATS</h1>
                    {founderStats.map(stat => StatContainer(stat))}
                </div>
            </div>
            
        );
    }
    buildAchievementContainers() {
        return (
            <div style={{backgroundColor: '#3a3a3a', textAlign: 'center', padding: '1vh', margin: '1vh', paddingTop:'30px'}}>
                <h1 style={{margin: 0}}>ACHIEVEMENTS</h1>
                <div id='achievementsContainer'>
                    {this.state.user.Achievements.map(achievement => AchievementContainer(achievement))}
                </div>
            </div>
        );   
    }
    buildCurrencyContainers() {
        const currencies = [];
        Object.keys(this.state.user.Currencies).map(currencyName => currencies.push({key: generateKey(), name: currencyName, image: currencyImages[currencyName], amount: this.state.user.Currencies[currencyName], earned: this.state.user.Stats[currencyName + 'Earned'], spent: this.state.user.Stats[currencyName + 'Spent']}));
        return (
            <div id='currencyContainerUserpage'>
                {currencies.map(currency => CurrencyContainer(currency))}
            </div>
        );
    }
    buildItemsContainer() {
        return (
            <div className='seenItemsContainer'>
                <h1 style={{margin: 0}}>SEEN ITEMS</h1>
                <h1 className='seenItemsSubtitle'>THROWABLES</h1>
                <ItemsCount seenItems={this.state.user.SeenItems.filter(seenItem => seenItem.Item.Type === 'Throwable')} totalItems={items.throwables}/>
                <div className='seenItemsSubContainer'>
                    {this.state.user.SeenItems.map(seenItem => { if(seenItem.Item.Type === 'Throwable') { return ItemContainer({ key: generateKey(), item: seenItem.Item, time: seenItem.TimeSeen }) }})}
                </div>
                <h1 className='seenItemsSubtitle'>SOUNDS</h1>
                <ItemsCount seenItems={this.state.user.SeenItems.filter(seenItem => seenItem.Item.Type === 'Sound')} totalItems={items.sounds}/>
                <div className='seenItemsSubContainer'>
                    {this.state.user.SeenItems.map(seenItem => { if(seenItem.Item.Type === 'Sound') { return ItemContainer({ key: generateKey(), item: seenItem.Item, time: seenItem.TimeSeen }) }})}
                </div>
                <h1 className='seenItemsSubtitle'>VIDEOS</h1>
                <ItemsCount seenItems={this.state.user.SeenItems.filter(seenItem => seenItem.Item.Type === 'Video')} totalItems={items.videos}/>
                <div className='seenItemsSubContainer'>
                    {this.state.user.SeenItems.map(seenItem => { if(seenItem.Item.Type === 'Video') { return ItemContainer({ key: generateKey(), item: seenItem.Item, time: seenItem.TimeSeen }) }})}
                </div>
                <h1 className='seenItemsSubtitle'>MANIFESTS</h1>
                <ItemsCount seenItems={this.state.user.SeenItems.filter(seenItem => seenItem.Item.Type === 'Greenscreen')} totalItems={items.greenscreens}/>
                <div className='seenItemsSubContainer'>
                    {this.state.user.SeenItems.map(seenItem => { if(seenItem.Item.Type === 'Greenscreen') { return ItemContainer({ key: generateKey(), item: seenItem.Item, time: seenItem.TimeSeen }) }})}
                </div>
                <h1 className='seenItemsSubtitle'>SCENES</h1>
                <ItemsCount seenItems={this.state.user.SeenItems.filter(seenItem => seenItem.Item.Type === 'Scene')} totalItems={items.scenes}/>
                <div className='seenItemsSubContainer'>
                    {this.state.user.SeenItems.map(seenItem => { if(seenItem.Item.Type === 'Scene') { return ItemContainer({ key: generateKey(), item: seenItem.Item, time: seenItem.TimeSeen }) }})}
                </div>
            </div>
        );
    }
    getLevelColor(level) {
        if (level < 25) { return lightColors["common"]; }
        if (level < 50) { return lightColors["uncommon"]; }
        if (level < 100) { return lightColors["rare"]; }
        if (level < 200) { return lightColors["epic"]; }
        else { return lightColors["legendary"]; }
    }
    render () {
        //If user was not found by the server
        if (this.state.userNotFound) {
            return (
                <div className='centerPage'>
                    <h1 className='infoMessage error'>{this.state.errorMessage}</h1>
                </div>
            ); 
        }
        //If user has not loaded yet
        if (this.state.user == null){
            return (
                <div className='centerPage'>
                    <h1 className='infoMessage'>Finding user...</h1>
                </div>
            );
        }
        return (
            <div id='mainDiv'>
                <div id='topBar' style={{justifyContent: 'center'}}>
                    <ReturnButton />
                    <UserSearchBar results={this.state.userSearchOutput}/>
                </div>
                <div id='userpageHeaderContainer'>
                    <div id='userInfoHeaderContainer'>
                        <div id='usernameText'>{this.state.user.Username}</div>
                        <div className='linebreak'></div>
                        <p>
                            <Link to={'/leaderboard/progression/level'} id='userLevelText' style={{color: this.getLevelColor(this.state.user.Progression.Level)}}>Lvl. {this.state.user.Progression.Level}</Link>
                        </p>
                    </div>
                    <ToxicityMeter toxicity={this.state.user.Toxicity}/>
                </div>
                <div id='dataContainer'>
                    {this.buildCurrencyContainers()}
                    {this.buildStatContainers()}
                    {this.buildAchievementContainers()}
                    {this.buildItemsContainer()}
                </div>
            </div>
        );
    }
}

export default withParams(Userpage);