import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import ReturnButton from './ReturnButton';
import './Leaderboard.css';
import statsMap from './stats.json';

var siWebSocket;

function kebabToPascal(name) {
    return name.replace(/(\b\w|-\w)/g, clearAndUpper);
}

function clearAndUpper(text) {
    return text.replace(/-/, "").toUpperCase();
}


function LeaderboardTable(props) {
    return (
        <table id='leaderboardTable'>
            <thead>
                <tr>
                    <th/>
                    <th/>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {props.data.map(entry => LeaderboardEntry({ data: entry, position: props.data.indexOf(entry) + 1}))}
            </tbody>
        </table>
    );
}

function LeaderboardEntry(props) {
    return (
        <tr className='leaderboardEntryContainer'>
            <td className='positionTableData'>
                {props.position}
            </td>
            <td className='userLinkTableData'>
                <Link to={'/user/' + props.data.User.UserId}>
                    {props.data.User.Username}
                </Link>
            </td>
            <td className='valueTableData'>
                {props.data.Value}
            </td>
        </tr>
    );
}

function Leaderboard(props) {
    siWebSocket = props.ws;
    const location = useLocation().pathname.replace(/\//g, '.');
    const statPath = kebabToPascal(location.replace('.leaderboard.', ''));
    const statServerName = statPath.substring(statPath.lastIndexOf('.') + 1);
    const statInfo = statsMap.find(stat => stat.serverName === statServerName);
    const [leaderboardData, setLeaderboardData] = useState([]);
    useEffect(() => {
        if (props.ws.webSocket.readyState) {
            connected();
        }
        siWebSocket.webSocket.onopen = () => connected();
        siWebSocket.webSocket.onerror = () => window.location = window.location.origin;
    }, []);
    const connected = () => {
        siWebSocket.webSocket.onmessage = (event) => {
            const receivedCommand = JSON.parse(event.data);
            if (!('Type' in receivedCommand)){
                return;
            }
            if (receivedCommand.Type == 'leaderboard-data') {
                setLeaderboardData(receivedCommand.UserStats);
            }
        }
        siWebSocket.sendCommand({
            type: 'get-leaderboard',
            statPath: statPath
        });
    }
    return (
        <div style={{width: '100%'}}>
            <ReturnButton/>
            <div id='leaderboardTableContainer'>
                <h1 id='leaderboardTitle'>{statInfo.name}</h1>
                <LeaderboardTable stat={statInfo} data={leaderboardData}/>
            </div>
        </div>
    );
}

export default Leaderboard;