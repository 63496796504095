import React from 'react';
import { useEffect, useState } from 'react';
import './main.css';
import './Requests.css';
import cancelImage from './media/icons/cancel.svg';
import completeImage from './media/icons/check.svg';
import bitImage from './media/bit.png';
import ReturnButton from './ReturnButton';
import diffStarIcon from './media/icons/osu/star2.png';
import osuDirectIcon from './media/icons/osu/osu-direct.svg';

import rankedIcon from './media/icons/osu/status/ranked.svg';
import lovedIcon from './media/icons/osu/status/loved.svg';
import pendingIcon from './media/icons/osu/status/pending.svg';
import qualifiedIcon from './media/icons/osu/status/qualified.svg';

import fruitsIcon from './media/icons/osu/gamemodes/mode-fruits.png';
import maniaIcon from './media/icons/osu/gamemodes/mode-mania.png';
import osuIcon from './media/icons/osu/gamemodes/mode-osu.png';
import taikoIcon from './media/icons/osu/gamemodes/mode-taiko.png';

var siWebSocket;
let prices;
let user;
let nowPlaying = 0;

function highlightLinks(text){
    if (text == null) { return; }
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    return text.split(' ').map(word => urlRegex.test(word) ? <a href={word}>{word} </a> : word + ' ');
}

function OnOffButton(props) {
    const getText = () => {
        if (props.requestsOn) { return 'ON'; }
        return 'OFF';
    }
    return (
        <button id='onOffButton' style={{backgroundColor: props.requestsOn ? '#4CAF50' : '#222222'}} disabled={!props.enabled} onClick={() => siWebSocket.sendCommand({ type: 'toggle-requests', toggle: !props.requestsOn })}>
            <p id='onOffButtonText'>REQUESTS ARE {getText()}</p>
        </button>
    );
}

function BoostRequestWindow(props) {
    let boostCommand = {
        type: 'boost-request',
        request: props.request
    }
    const getVisbility = () => {
        if (props.request == null) { return 'hidden'; }
        return 'visible';
    }
    const handleClick = () => {
        siWebSocket.sendCommand(boostCommand);
        props.boost(null);
    }
    if (props.request == null) { return; }
    return (
        <div id='boostRequestWindow' style={{visibility: getVisbility()}}>
            <h1 style={{margin: 0}}>BOOST REQUEST?</h1>
            <p>{props.request.Owner.Username} : {props.request.Message}</p>
            <div className='popupButtonsContainer'>
                <button className='popupWindowButton cancel' onClick={() => props.boost(null)}>CANCEL</button>
                <button className='popupWindowButton send' onClick={() => handleClick()}>
                    <div style={{display: 'flex', alignItems: 'center', padding: 0}}>
                        <img src={bitImage} style={{width: '25px', marginRight: '5px'}}/>
                        <span>{prices.UsePrices.BoostRequest.Amount}</span>
                    </div>
                </button>
            </div>
        </div>
    );
}

function RequestGroup(props) {
    if (props.requests.length == 0) {
        return;
    }
    return (
        <div key={props.key} className='requestGroupContainer'>
            {props.requests.map(activeRequest => Request({ key: crypto.randomUUID(), type: 'active', request: activeRequest, boost: props.boost, activeUsers: props.activeUsers}))}
        </div>
    );
}

function RemoveRequestButton(props){
    let removeCommand = {
        type: 'remove-request',
        request: props.request
    }
    if (props.isEnabled) {
        return (
            <button className='requestButton' onClick={() => {siWebSocket.sendCommand(removeCommand)}}>
              <img src={cancelImage} className='requestButtonImage'></img>
            </button>
          );
    }
    return;
}

function CompleteRequestButton(props){
    let completeCommand = {
        type: 'complete-request',
        request: props.request
    }
    if (props.isEnabled) {
        return (
            <button className='requestButton' onClick={() => {siWebSocket.sendCommand(completeCommand)}}>
              <img src={completeImage} className='requestButtonImage'></img>
            </button>
        )
    }
}

function BoostRequestButton(props){
    return (
        <button className='requestButton boost' onClick={() => props.boost(props.request)} disabled={!props.enabled}>
            <img src={bitImage} className='requestButtonImage'/>
        </button>
    );
}

function getTimeStringFromDateTime(timestamp) {
    const datetime = new Date(timestamp);
    const extraZero = datetime.getMinutes() < 10 ? '0' : '';
    return datetime.getHours() + ':' + extraZero + datetime.getMinutes();
}

function EmptyRequest(props) {
    const renderButtons = () => {
        if (user == null || user.AccessLevel > 0 || props.type === 'completed') {
            return;
        }
        return (
            <div className='requestButtonsContainer'>
                <CompleteRequestButton request={props.request} isEnabled={true}/>
                <RemoveRequestButton request={props.request} isEnabled={true}/>                   
            </div>
        );
    }
    return (
        <div key={props.key} className='requestContainer'>
            {renderButtons()}
            <RequestContent request={props.request}/>
        </div>
    );
}

function InactiveRequest(props) {
    if (props.request.Owner == null) {
        return;
    }
    const renderButtons = () => {
        if (user == null || user.AccessLevel > 0) {
            return;
        }
        return (
            <div className='requestButtonsContainer'>
                <CompleteRequestButton request={props.request} isEnabled={true}/>
                <RemoveRequestButton request={props.request} isEnabled={true}/>                   
            </div>
        );
    }
    return (
        <div key={props.key} className='requestContainer'>
            {renderButtons()}
            <div className='requestTextContainer' style={{backgroundColor: props.request.IsBoosted ? '#642f99' : 'transparent'}}>
                <span className='requestText timestamp'>[{getTimeStringFromDateTime(props.request.Timestamp)}]</span>
                <span className='requestText username inactive'>{props.request.Owner.Username}</span>
                <span className='requestText usernameDivider'>{'>>'}</span>
                <span className='requestText message' style={{wordBreak: 'break-all'}}>{highlightLinks(props.request.Message)}</span>
            </div>
        </div>
    );
}

function Request(props) {
    if (props.request.IsFreeRequest) {
        return EmptyRequest(props);
    }
    let removeEnabled = false;
    if (user != null){
        if (user.UserId === props.request.Owner.UserId || user.AccessLevel <= 0) {
            removeEnabled = true;
        }
    }
    let completeEnabled = false;
    if (user != null){
        if (user.AccessLevel <= 0) {
            completeEnabled = true;
        }
    }
    let boostEnabled = false;
    if (user != null && prices != null){
        if (user.Currencies.Bits >= prices.UsePrices.BoostRequest.Amount) {
            boostEnabled = true;
        }
    }
    const removeButton = () => {
        if (removeEnabled) {
            return <RemoveRequestButton request={props.request} isEnabled={removeEnabled}/>;
        }
    }
    const completeButton = () => {
        if (completeEnabled) {
            return <CompleteRequestButton request={props.request} isEnabled={completeEnabled}/>;
        }
    }
    const boostButton = () => {
        if (!props.request.IsBoosted && removeEnabled){
            return <BoostRequestButton request={props.request} boost={props.boost} enabled={boostEnabled}/>;
        }
    }
    const renderButtons = () => {
        if (props.type === 'completed') { return; }
        return (
            <div className='requestButtonsContainer'>
                {boostButton()}
                {completeButton()}
                {removeButton()}                    
            </div>
        );
    }
    return (
        <div key={props.key} className='requestContainer'>
            {renderButtons()}
            <RequestContent request={props.request}/>
        </div>
    );
}

function RequestContent(props) {
    if (props.request.IsFreeRequest) {
        return (
            <div className='requestContentContainer freeRequest'>
                <span className='requestText freeRequest'>FREE SPACE</span>
            </div>
        )
    }
    const getStatusIcon = () => {
        switch (props.request.Beatmap.Status) {
            case 'graveyard':
                return <img className='requestImage status' src={pendingIcon}></img>;
            case 'wip':
                return <img className='requestImage status' src={pendingIcon}></img>;
            case 'pending':
                return <img className='requestImage status' src={pendingIcon}></img>;
            case 'ranked':
                return <img className='requestImage status' src={rankedIcon}></img>;
            case 'approved':
                return <img className='requestImage status' src={qualifiedIcon}></img>;
            case 'qualified':
                return <img className='requestImage status' src={qualifiedIcon}></img>;
            case 'loved':
                return <img className='requestImage status' src={lovedIcon}></img>;
            default:
                return;
        } 
    }
    const getModeIcon = () => {
        switch (props.request.Beatmap.Mode) {
            case 'osu':
                return <img className='requestImage mode' src={osuIcon}></img>;
            case 'taiko':
                return <img className='requestImage mode' src={taikoIcon}></img>;
            case 'fruits':
                return <img className='requestImage mode' src={fruitsIcon}></img>
            case 'mania':
                return <img className='requestImage mode' src={maniaIcon}></img>;
            default:
                return;
        }
    }
    const getDuration = () => {
        const seconds = props.request.Beatmap.TotalLength;
        const minutes = seconds / 60;
        const minuteAmount = Math.floor(minutes);
        const secondsAmount = seconds - minuteAmount * 60;
        return `${minuteAmount}:${secondsAmount}`;
    }
    const getNowPlayingColor = () => {
        if (nowPlaying === props.request.Beatmap.Id) {
            return '#ffa60060';
        }
        return '#0000001f';
    }
    const getBeatmapInfo = () => {
        if (props.request.Beatmap == null || props.request.Beatmap.Beatmapset == null) {
            return;
        }
        return (
            <div className='beatmapInfoContainer'>
                <div className='beatmapIconsContainer'>
                    {getModeIcon()}
                    {getStatusIcon()}
                </div>
                <div>
                    <a href={'osu://b/' + props.request.Beatmap.Id} className='osuDirectButton'>
                        <img src={osuDirectIcon}></img>
                    </a>
                </div>
                <div className='beatmapTextContainer' style={{backgroundColor: getNowPlayingColor()}}>
                    <a href={props.request.Beatmap.Url} target='_blank' rel='noreferrer noopener' className='requestLink'>{props.request.Beatmap.Beatmapset.Artist} - {props.request.Beatmap.Beatmapset.Title}</a>
                    <p className='beatmapVersion'>{props.request.Beatmap.Version}</p>
                    <div className='beatmapDetailsContainer'>
                        <span className='beatmapDetailsText duration'>[{getDuration()}]</span>
                        <span className='beatmapDetailsText bpm'>BPM: {props.request.Beatmap.Bpm}</span>
                        <div className='difficultyRatingContainer'>
                            <img src={diffStarIcon}></img>
                            <span>{props.request.Beatmap.DifficultyRating}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
    return (
        <div className='requestContentContainer'>
            <div className='requestTextContainer'>
                <span className='requestText timestamp'>[{getTimeStringFromDateTime(props.request.Timestamp)}]</span>
                <span className='requestText username'>{props.request.Owner.Username}</span>
                <span className='requestText usernameDivider'>{'>>'}</span>
                <span className='requestText message' style={{wordBreak: 'break-all'}}>{highlightLinks(props.request.Message)}</span>
            </div>
            {getBeatmapInfo()}
        </div>
    );
}

class Requests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRequestGroups: [],
            boostedRequests: [],
            inactiveRequests: [],
            completedRequests: [],
            user: [],
            connected: false,
            requestsOn: true,
            requestToBoost: null,
            activeRequesters: [],
            prices: null,
            nowPlayingId: 0
        }
    }
    componentDidMount() {
        if (this.props.ws.webSocket.readyState) {
            this.webSocketConnected();
        } 
        this.props.ws.webSocket.onopen = () => this.webSocketConnected();
    }
    webSocketConnected() {
        siWebSocket = this.props.ws;
        siWebSocket.init();
        siWebSocket.webSocket.onmessage = (event) => {
            const receivedCommand = JSON.parse(event.data);
            console.log(receivedCommand);
            switch(receivedCommand.Type) {
                case 'requests-update':
                    this.handleRequestsUpdate(receivedCommand);
                    break;
                case 'loggedIn':
                    user = receivedCommand.User;
                    this.setState({ user: receivedCommand.User });
                    break;
                case 'toggle-requests':
                    this.setState({ requestsOn: receivedCommand.RequestsOn });
                    break;
                case 'prices-update':
                    prices = receivedCommand.Prices;
                    this.setState({ prices: receivedCommand.Prices });
                    break;
                case 'user-data':
                    this.handleUserDataUpdate(receivedCommand);
                    break;
                case 'active-users':
                    this.handleSetActiveRequesters(receivedCommand);
                    break;
                case 'active-users-update':
                    this.handleActiveUsersUpdate(receivedCommand.UserChanges);
                    break;
                case 'new-lurk':
                    this.handleNewLurk(receivedCommand);
                    break;
                case 'now-playing-update':
                    this.setState({ nowPlayingId: receivedCommand.Id });
                    nowPlaying = receivedCommand.Id;
                default:
                    break;
            }
        }
        siWebSocket.checkAuth();
        siWebSocket.sendCommand({ type: 'get-active-users' });
        siWebSocket.sendCommand({ type: 'get-requests' });
        siWebSocket.sendCommand({ type: 'prices-request' });
        this.setState({ connected: true });
    }
    handleSetActiveRequesters(command) {
        const activeUsers = Array.from(command.ActiveUsers);
        command.LurkingUsers.forEach(lurkingUser => {
            const foundUser = activeUsers.find(user => user.UserId == lurkingUser.UserId);
            if (foundUser == undefined) {
                return;
            }
            activeUsers.splice(activeUsers.indexOf(foundUser), 1);
        });
        this.setState({ activeRequesters: activeUsers });
    }
    handleActiveUsersUpdate(userChanges) {
        if (this.state.activeRequesters == null || userChanges == null || userChanges.length < 1) { return; }
        const newActiveUsers = Array.from(this.state.activeRequesters);
        userChanges.forEach(change => {
            if(change.IsActive && !this.state.activeRequesters.includes(this.state.activeRequesters.find(user => user.UserId == change.User.UserId))) {
                newActiveUsers.push(change.User);
            }
            else if (!change.IsActive && newActiveUsers.includes(newActiveUsers.find(user => user.UserId == change.User.UserId))) {
                newActiveUsers.splice(newActiveUsers.indexOf(newActiveUsers.find(user => user.UserId == change.User.UserId)), 1);
            }
        });
        this.setState({ activeRequesters: newActiveUsers });
    }
    handleNewLurk(newLurk) {
        const newUsers = Array.from(this.state.activeRequesters);
        if (newLurk.IsLurking) {
            newUsers.splice(newUsers.indexOf(newUsers.find(user => user.UserId == newLurk.User.UserId)), 1);
        }
        else {
            newUsers.push(newLurk.User);
        }
        this.setState({ activeRequesters: newUsers });
    }
    handleUserDataUpdate(userDataCommand) {
        if (userDataCommand.UserDataType === 'User') {
            user = userDataCommand.UserData;
            this.setState({ user: userDataCommand.UserData });
            return;
        }
        const newUser = Object.assign({}, user);
        newUser[userDataCommand.UserDataType] = userDataCommand.UserData;
        user = newUser;
        this.setState({ user: newUser });
    }

    handleRequestsUpdate(command) {
        this.setState({ activeRequestGroups: command.ActiveRequestGroups, completedRequests: command.CompletedRequests });
    }

    getActiveRequestGroups() {
        const activeUserIds = this.state.activeRequesters.map(user => user.UserId);
        const activeRequestGroups = JSON.parse(JSON.stringify(this.state.activeRequestGroups));
        activeRequestGroups.forEach(group => {
            group.Requests = group.Requests.filter(request => !request.IsBoosted && (request.Owner == null || activeUserIds.includes(request.Owner.UserId)));
        });
        return activeRequestGroups;
    }

    getBoostedRequests() {
        const boostedRequests = [];
        const activeUserIds = this.state.activeRequesters.map(user => user.UserId);
        this.state.activeRequestGroups.forEach(group => {
            boostedRequests.push(...group.Requests.filter(request => request.IsBoosted && request.Owner != null && activeUserIds.includes(request.Owner.UserId)));
        });
        return boostedRequests;
    }

    getInactiveRequests() {
        const activeUserIds = this.state.activeRequesters.map(user => user.UserId);
        const inactiveRequests = [];
        this.state.activeRequestGroups.forEach(group => {
            inactiveRequests.push(...group.Requests.filter(request => request.Owner != null && !activeUserIds.includes(request.Owner.UserId)));
        });
        return inactiveRequests;
    }

    renderBoostedRequests() {
        const boostedRequests = this.getBoostedRequests();
        if (boostedRequests.length == 0) {
            return;
        }
        return (
            <div className='requestsContainer boosted'>
                <div className='requestGroupContainer boosted'>
                    {this.getBoostedRequests().map(request => Request({ key: crypto.randomUUID(), user: this.state.user, type: 'boosted', request: request}))}
                </div>
            </div>
        );
    }

    renderInactiveRequests() {
        const inactiveRequests = this.getInactiveRequests();
        if (inactiveRequests.length == 0) {
            return;
        }
        return (
            <div className='requestsContainer inactive'>
                <div className='requestGroupContainer inactive'>
                    {this.getInactiveRequests().map(request => InactiveRequest({ key: crypto.randomUUID(), user: this.state.user, type: 'inactive', request: request}))}
                </div>
            </div>
        );
    }

    renderCompletedRequests() {
        if (this.state.completedRequests.length == 0) {
            return;
        }
        return (
            <div className='requestsContainer completed'>
                <div className='requestGroupContainer completed'>
                    {this.state.completedRequests.map(request => Request({ key: crypto.randomUUID(), user: this.state.user, type: 'completed', request: request}))}
                </div>
            </div>
        );
    }

    getRequestToggleEnabled() {
        if (user == null) { return false; }
        if (user.AccessLevel <= 0) { return true; }
        return false;
    }
    changeRequestToBoost(request) {
        this.setState({ requestToBoost: request });
    }
    render() {
        if (!this.props.ws.webSocket.OPEN) {
            return;
        }
        return (
            <div>
                <ReturnButton/>
                <div style={{textAlign: 'center'}}>
                    <OnOffButton requestsOn={this.state.requestsOn} enabled={this.getRequestToggleEnabled()}/>
                    <div className='requestsSection light'>
                        <h1>Playlist : {this.state.activeRequestGroups.reduce((prev, requestGroup) => prev + requestGroup.Requests.length, 0)}</h1>
                        <div className='linebreak'></div>
                        {this.renderBoostedRequests()}
                        <div className='requestsContainer active'>
                            {this.getActiveRequestGroups().map(requestGroup => RequestGroup({ key: crypto.randomUUID(), requests: requestGroup.Requests, boost: this.changeRequestToBoost.bind(this) }))}
                        </div>
                        {this.renderInactiveRequests()}
                    </div>
                    <div className='requestsSection dark'>
                        <h1>Completed Maps : {this.state.completedRequests.length}</h1>
                        <div className='linebreak'></div>
                        {this.renderCompletedRequests()}
                    </div>
                </div>
                <BoostRequestWindow request={this.state.requestToBoost} boost={() => this.changeRequestToBoost()}/>
            </div>
        );
    }
}

export default Requests;