import itemsJson from './items.json';

function importAll(r) {
    return r.keys().map(r);
}

class DataLoader {
    loadThrowables() {
        const throwables = [];
        itemsJson.Throwables.forEach(throwableData => {
        let newThrowable = {
            name: throwableData.Name,
            friendlyName: throwableData.FriendlyName,
            rarity: throwableData.Rarity,
            image: require(`./media/throwables/${throwableData.Name}.png`)
        }
        throwables.push(newThrowable);
        });
        return throwables;
    }

    loadSounds() {
        const sounds = [];
        itemsJson.Sounds.forEach(soundData => {
            let newSound = {
            name: soundData.Name,
            friendlyName: soundData.FriendlyName,
            rarity: soundData.Rarity
            }
            sounds.push(newSound);
        });
        return sounds;
    }

    loadVideos() {
        const videos = [];
        itemsJson.Videos.forEach(videoData => {
        let newVideo = {
            name: videoData.Name,
            friendlyName: videoData.FriendlyName,
            rarity: videoData.Rarity,
            length: videoData.Length,
            image: require(`./media/video-previews/${videoData.Name}.png`)
        }
        videos.push(newVideo);
        });
        return videos;
    }

    loadGreenscreens() {
        const greenscreens = [];
        itemsJson.Greenscreens.forEach(greenscreenData => {
        let newGreenscreen = {
            name: greenscreenData.Name,
            friendlyName: greenscreenData.FriendlyName,
            rarity: greenscreenData.Rarity,
            image: require(`./media/greenscreen-previews/${greenscreenData.Name}.gif`)
        }
        greenscreens.push(newGreenscreen);
        });
        return greenscreens;
    }

    loadScenes() {
        const scenes = [];
        itemsJson.Scenes.forEach(sceneData => {
        let newScene = {
            name: sceneData.Name,
            friendlyName: sceneData.FriendlyName,
            rarity: sceneData.Rarity,
            length: sceneData.Length,
            image: require(`./media/scene-previews/${sceneData.Name}.png`)
        }
        scenes.push(newScene);
        });
        return scenes;
    }
}

export default DataLoader;