import './main.css';
import './App.scss';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import DataLoader from './dataLoader';
import rewardsJson from './rewards.json';

import plusIcon from './media/icons/plus.svg';
import twitch from './media/icons/twitch.svg';
import twitchSmall from './media/icons/twitch-small.svg';
import banana from './media/banana.png';
import token from './media/token.png';
import cash from './media/cash.png';
import bits from './media/bit.png';
import subPoint from './media/sub-point.png';
import goldenBanana from './media/golden-banana.png';
import shuffleIcon from './media/icons/shuffle.svg';
import rocks from './media/icons/rocks.png';
import audio from './media/icons/audio.svg';
import video1 from './media/icons/video1.svg';
import sceneIcon from './media/icons/scene.svg';
import fireworkIcon from './media/icons/firework.svg';
import youtubeIcon from './media/icons/youtube.svg';
import bossKeyIcon from './media/icons/backspace.svg';
import magicWandIcon from './media/icons/magic-wand.svg';
import cancelIcon from './media/icons/cancel.svg';
import lockIcon from './media/icons/lock.svg';
import bananaTreeIcon from './media/banana-tree.png';
import greenCircleArrow from './media/gb-respec-arrow.png';

//#region Globals
const colors = {
  legendary: "#e69e19",
  epic: "#8f23c2",
  rare: "#3773ac",
  uncommon: "#4CAF50",
  common: "#b6b6b6",
  buttonRed: '#b33737',
  buttonNeutral: '#545454',
  textGreen: '#1ebb1e',
  textRed: '#f53333'
}
const darkColors = {
  legendary: "#753e0b",
  epic: "#5b0782",
  rare: "#183078",
  uncommon: "#21541c",
  common: "#595959",
  buttonNeutral: '#2b2b2b',
  buttonRed: '#691222'
}
const lightColors = {
  legendary: "#ffd269",
  epic: "#e175ff",
  rare: "#8ad5f2",
  uncommon: "#a5fc97",
  common: "#dbdbdb",
  buttonNeutral: '#a1a1a1',
  buttonRed: '#db5a51'
}


var siWebSocket;
var user;
var prices;
var togglePopup;

const dataLoader = new DataLoader();
const throwables = dataLoader.loadThrowables();
const sounds = dataLoader.loadSounds();
const greenscreens = dataLoader.loadGreenscreens();
const videos = dataLoader.loadVideos();
const scenes = dataLoader.loadScenes();

const basicScenes = [
  { name: 'osu!', text: 'Main' },
  { name: 'Full Cam', text: 'Camera' },
  { name: 'osu! Song Info', text: 'Map Info' },
  { name: 'IP', text: 'Reveal IP' }
]

const pressableKeys = [
  'BOSS', 'RANDOM'
];

const isMobile = window.innerWidth < window.innerHeight;
//#endregion Globals

//#region HelperFunctions
function getRandom(array){
  return array[Math.floor(Math.random() * array.length)];
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateKey(){
  return Math.floor(Math.random() * 100000);
}

function getCurrencyDeltas (oldState, newState) {
  const newDeltas = {
    bananas: 0,
    tokens: 0,
    cash: 0,
    bits: 0,
    subPoints: 0,
    goldenBananas: 0
  };
  if (newState.Currencies !== oldState.Currencies){
    if (newState.Currencies.Bananas != oldState.Currencies.Bananas){
      const delta = newState.Currencies.Bananas - oldState.Currencies.Bananas;
      newDeltas.bananas = delta;
    }
    if (newState.Currencies.McTokens != oldState.Currencies.McTokens){
      const delta = newState.Currencies.McTokens - oldState.Currencies.McTokens;
      newDeltas.tokens = delta;
    }
    if (newState.Currencies.Cash != oldState.Currencies.Cash){
      const delta = newState.Currencies.Cash - oldState.Currencies.Cash;
      newDeltas.cash = delta;
    }
    if (newState.Currencies.Bits != oldState.Currencies.Bits){
      const delta = newState.Currencies.Bits - oldState.Currencies.Bits;
      newDeltas.bits = delta;
    }
    if (newState.Currencies.SubPoints != oldState.Currencies.SubPoints){
      const delta = newState.Currencies.Bits - oldState.Currencies.Bits;
      newDeltas.subPoints = delta;
    }
    if (newState.Currencies.GoldenBananas != oldState.Currencies.GoldenBananas){
      const delta = newState.Currencies.Bits - oldState.Currencies.Bits;
      newDeltas.goldenBananas = delta;
    }
  }
  return newDeltas;
}
//#endregion HelperFunctions

const ItemRollPopup = React.memo((props) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (props.itemRoll == null) { return; }
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  });
  if (props.itemRoll == null) { return; }
  const getRarityByItemType = itemType => {
    switch(itemType.toLowerCase()){
      case 'video':
        return 'uncommon';
      case 'scene':
        return 'uncommon';
      case 'greenscreen':
        return 'uncommon';
      default:
        return 'common';
    }
  }
  return (
    <div ref={ref} className='itemRollPopupContainer'>
      <ItemRollCanvas rarity={getRarityByItemType(props.itemRoll.ItemType)} itemRoll={props.itemRoll} width={width} height={height}/>
    </div>
  );
});

function ItemRollCanvas(props) {
  const canvasRef = useRef(null);
  
  const bananaImage = new Image();
  bananaImage.src = banana;
  const tokenImage = new Image();
  tokenImage.src = token;
  const cashImage = new Image();
  cashImage.src = cash;
  const bitsImage = new Image();
  bitsImage.src = bits;
  const goldenBananaImage = new Image();
  goldenBananaImage.src = goldenBanana;

  const rollTime = 333;
  const initialRollSpeed = 4;
  const finalRollSpeed = 7;
  const images = [bananaImage, tokenImage, cashImage, bitsImage, goldenBananaImage];
  let rollStage = 0;
  let rollSpeed = initialRollSpeed;
  let frameCount = 0;
  const slotImages = [getRandom(images), getRandom(images), getRandom(images)];
  const rollSpeedInterval = setInterval(() => {rollSpeed++}, rollTime/(finalRollSpeed - initialRollSpeed));
  let resultRarity = 'common';
  let rollFinished = false;

  const resultName = props.itemRoll.Result.FriendlyName;
  const resultImage = new Image();
  switch (props.itemRoll.ItemType.toLowerCase()){
    case 'throwable':
      resultImage.src = throwables.filter(throwable => throwable.name === props.itemRoll.Result.Name)[0].image;
      break;
    case 'sound':
      resultImage.src = audio;
      break;
    case 'video':
      resultImage.src = videos.filter(video => video.name === props.itemRoll.Result.Name)[0].image;
      break;
    case 'scene':
      resultImage.src = scenes.filter(scene => scene.name === props.itemRoll.Result.Name)[0].image;
      break;
    case 'greenscreen':
      resultImage.src = greenscreens.filter(greenscreen => greenscreen.name === props.itemRoll.Result.Name)[0].image;
      break;
    default:
      resultImage.src = plusIcon;
      break;
  }

  let rollTotal;
  switch (props.itemRoll.ResultRarity.toLowerCase()){
    case 'common':
      rollTotal = getRandomInt(3, 5);
      break;
    case 'uncommon':
      rollTotal = getRandomInt(6, 8);
      break;
    case 'rare':
      rollTotal = getRandomInt(9, 11);
      break;
    case 'epic':
      rollTotal = getRandomInt(12, 14);
      break;
    case 'legendary':
      rollTotal = 15;
      break;
    default:
      rollTotal = 3;
      break;
  }
  const firstImageRarityInt = getRandomInt(rollTotal/3, Math.min(rollTotal - 2, 5));
  const secondImageRarityInt = getRandomInt((rollTotal - firstImageRarityInt)/2, Math.min(rollTotal - firstImageRarityInt - 1, 5));
  const thirdImageRarityInt = rollTotal - firstImageRarityInt - secondImageRarityInt;
  const finalSlotImages = [images[firstImageRarityInt - 1], images[secondImageRarityInt - 1], images[thirdImageRarityInt - 1]];

  const getBackgroundColor = () => {
    return darkColors[props.rarity];
  }

  const getStrokeColor = () => {
    return colors[props.rarity];
  }

  const selectFillStyle = (ctx, image) => {
    switch (images.indexOf(image)){
      case 0:
        ctx.fillStyle = colors["common"];
        ctx.strokeStyle = lightColors["common"];
        break;
      case 1:
        ctx.fillStyle = colors["uncommon"];
        ctx.strokeStyle = lightColors["uncommon"];
        break;
      case 2:
        ctx.fillStyle = colors["rare"];
        ctx.strokeStyle = lightColors["rare"];
        break;
      case 3:
        ctx.fillStyle = colors["epic"];
        ctx.strokeStyle = lightColors["epic"];
        break;
      case 4:
        ctx.fillStyle = colors["legendary"];
        ctx.strokeStyle = lightColors["legendary"];
        break;
      default:
        ctx.fillStyle = colors["common"];
        ctx.strokeStyle = lightColors["common"];
        break;
    }
  }

  const update = ctx => {
    frameCount++;
    if (rollSpeed >= finalRollSpeed){
      rollStage++;
      frameCount = 0;
      rollSpeed = initialRollSpeed;
    }
    switch(rollStage){
      case 0:
        if (frameCount % rollSpeed == 0){
          const otherImages = images.slice();
          otherImages.splice(otherImages.indexOf(slotImages[0]), 1);
          slotImages[0] = getRandom(otherImages);
        }
        break;
      case 1:
        slotImages[0] = finalSlotImages[0];
        if (frameCount % rollSpeed == 0){
          const otherImages = images.slice();
          otherImages.splice(otherImages.indexOf(slotImages[1]), 1);
          slotImages[1] = getRandom(images);
        }
        break;
      case 2:
        slotImages[1] = finalSlotImages[1];
        if (frameCount % rollSpeed == 0){
          const otherImages = images.slice();
          otherImages.splice(otherImages.indexOf(slotImages[2]), 1);
          slotImages[2] = getRandom(images);
        }
        break;
      default:
        //Roll is finished
        slotImages[2] = finalSlotImages[2];
        resultRarity = props.itemRoll.ResultRarity.toLowerCase();
        clearInterval(rollSpeedInterval);
        rollFinished = true;
    }
    draw(ctx);
  }

  const draw = ctx => {
    //Clear frame
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    //Draw background and border
    ctx.fillStyle = getBackgroundColor();
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.strokeStyle = getStrokeColor();
    ctx.lineWidth = ctx.canvas.width/50;
    ctx.strokeRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    //Draw slot boxes and outlines
    const slotWindowWidth = 2* ctx.canvas.width / 3;
    const slotWindowHeight = ctx.canvas.height;
    ctx.fillStyle = "#FFF";
    const squareSize = ctx.canvas.height/2;
    const squareSpacing = (slotWindowWidth - 3 * squareSize) / 4;
    ctx.strokeStyle = "#a8a8a8";
    ctx.lineWidth = squareSize/10;
    selectFillStyle(ctx, slotImages[0]);
    ctx.fillRect(squareSpacing, slotWindowHeight/2 - squareSize/2, squareSize, squareSize);
    ctx.strokeRect(squareSpacing, slotWindowHeight/2 - squareSize/2, squareSize, squareSize);
    selectFillStyle(ctx, slotImages[1]);
    ctx.fillRect(2 * squareSpacing + squareSize, ctx.canvas.height/2 - squareSize/2, squareSize, squareSize);
    ctx.strokeRect(2 * squareSpacing + squareSize, ctx.canvas.height/2 - squareSize/2, squareSize, squareSize);
    selectFillStyle(ctx, slotImages[2]);
    ctx.fillRect(3 * squareSpacing + 2 * squareSize, ctx.canvas.height/2 - squareSize/2, squareSize, squareSize);
    ctx.strokeRect(3 * squareSpacing + 2 * squareSize, ctx.canvas.height/2 - squareSize/2, squareSize, squareSize);
    //Draw slot images
    ctx.imageSmoothingEnabled = false;
    const imageSize = squareSize * 0.5;
    ctx.drawImage(slotImages[0], squareSpacing + (squareSize - imageSize)/2, ctx.canvas.height/2 - squareSize/2 + (squareSize - imageSize)/2, imageSize, imageSize);
    if (rollStage > 0){
      ctx.drawImage(slotImages[1], 2 * squareSpacing + squareSize + (squareSize - imageSize)/2, ctx.canvas.height/2 - squareSize/2 + (squareSize - imageSize)/2, imageSize, imageSize);
    }
    if (rollStage > 1){
      ctx.drawImage(slotImages[2], 3 * squareSpacing + 2 * squareSize + (squareSize - imageSize)/2, ctx.canvas.height/2 - squareSize/2 + (squareSize - imageSize)/2, imageSize, imageSize);
    }
    //Draw arrow
    ctx.fillStyle = lightColors[props.rarity];
    const arrowLeftOffset = -ctx.canvas.width/22;
    const arrowRightOffset = ctx.canvas.width/60;
    const arrowBaseHeight = ctx.canvas.width/25;
    const arrowBaseWidth = ctx.canvas.width/15
    const arrowTipHeight = arrowBaseHeight * 2;
    const arrowTipWidth = arrowBaseHeight;
    ctx.fillRect(slotWindowWidth + arrowLeftOffset + arrowRightOffset, (ctx.canvas.height - arrowBaseHeight)/2, arrowBaseWidth, arrowBaseHeight);
    ctx.beginPath();
    ctx.moveTo(slotWindowWidth + arrowBaseWidth + arrowLeftOffset + arrowRightOffset - 1, ctx.canvas.height/2 - arrowTipHeight/2);
    ctx.lineTo(slotWindowWidth + arrowBaseWidth + arrowLeftOffset + arrowRightOffset - 1, ctx.canvas.height/2 + arrowTipHeight/2);
    ctx.lineTo(slotWindowWidth + arrowBaseWidth + arrowTipWidth + arrowRightOffset + arrowLeftOffset, ctx.canvas.height/2);
    ctx.fill();
    //Draw result box
    ctx.fillStyle = colors[resultRarity];
    ctx.strokeStyle = lightColors[resultRarity];
    const resultSize = squareSize * 1.4;
    const resultMargin = (ctx.canvas.width - (slotWindowWidth + arrowBaseWidth + arrowTipWidth + arrowLeftOffset) - resultSize)/2;
    const resultBoxX = slotWindowWidth + arrowBaseWidth + arrowTipWidth + arrowLeftOffset + resultMargin;
    ctx.fillRect(resultBoxX, ctx.canvas.height/2 - resultSize/2, resultSize, resultSize);
    ctx.strokeRect(resultBoxX, ctx.canvas.height/2 - resultSize/2, resultSize, resultSize);
    if (rollFinished) {
      //Draw result image
      const resultImageRatio = resultImage.width / resultImage.height;
      let resultImageWidth = resultSize * 0.5;
      let resultImageHeight = resultImageWidth / resultImageRatio;
      if (resultImage.height > resultImage.width){
        resultImageHeight = resultSize * 0.5;
        resultImageWidth = resultImageRatio * resultImageHeight;
      }
      const resultImagePositionX = resultBoxX + resultSize/2 - resultImageWidth/2;
      const resultImagePositionY = ctx.canvas.height/2 - resultImageHeight/2;
      ctx.drawImage(resultImage, resultImagePositionX, resultImagePositionY + 10, resultImageWidth, resultImageHeight);
      //Draw result text
      ctx.fillStyle = darkColors[resultRarity];
      const fontSize = ctx.canvas.height/10;
      ctx.font = fontSize + 'px Lilian';
      ctx.textAlign = 'center';
      ctx.fillText(resultName, resultBoxX + resultSize/2, ctx.canvas.height/2 - resultSize/2 + 30, resultSize - ctx.lineWidth);
    }
    
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = props.width;
    canvas.height = props.height;
    const ctx = canvas.getContext('2d');
    const interval = setInterval(() => update(ctx), 16);
    return () => clearInterval(interval);
  }, [update]);
  return (
    <canvas ref={canvasRef}/>
  );
}

//#region Header
function TwitchLoginButton(props) {
  let nonce = Math.floor(Math.random() * 10000).toString();
  let authUrl = "https://id.twitch.tv/oauth2/authorize" +
    "?response_type=token+id_token" +
    "&client_id=i4dpsr49td5aceqbepu5aj0lt4ahia" +
    "&claims={\"id_token\":{\"sub\":null, \"preferred_username\":null}}" +
    "&redirect_uri=" + window.location.origin + "/authorize/" +
    "&scope=openid" +
    "&state=" + nonce +
    "&nonce=" + nonce;
  return (
    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
      <button disabled={!props.isEnabled} className='twitchLoginButton' onClick={() => {
        let command = {
          type: 'auth-init',
          nonce: nonce
        }
        siWebSocket.sendCommand(command)
        window.location=authUrl;
        }}>
        <span style={{fontSize: '3.5vh', textAlign: 'center'}}>
          <p>LOGIN WITH</p>
          <img className='twitchLogo' src={twitch} alt="TWITCH"/>
        </span>
      </button>
    </div>
  );
}

const XpBar = React.memo(props => {
  if (user == null) { return; }
  const foregroundWidth = user.Progression.XpTowardNextLevel / user.Progression.XpNeededForNextLevel * 100;
  const getLevelColor = (level) => {
    if (level < 25) { return lightColors["common"]; }
    if (level < 50) { return lightColors["uncommon"]; }
    if (level < 100) { return lightColors["rare"]; }
    if (level < 200) { return lightColors["epic"]; }
    return lightColors["legendary"];
  }
  const getNewXp = () => {
    if (props.newXp == null) { return; }
    let color = colors.textGreen;
    if (props.newXp.Amount < 0) { color = colors.textRed; }
    return (
      <span id='newXpIndicator' key={generateKey()} style={{color: color}}>+{props.newXp.Amount} {props.newXp.FlavorText}</span>
    );
  }
  return (
    <div id='xpBar'>
      <span className='xpBarLevelIndicator' style={{color: getLevelColor(user.Progression.Level)}}>{user.Progression.Level}</span>
      <div id='xpBarBackground'>
        <div id='xpBarForeground' style={{width: foregroundWidth.toString() + "%", backgroundColor: getLevelColor(user.Progression.Level + 1)}}>
        </div>
        <div id='xpBarTooltip'>
          <span>{Math.floor(user.Progression.XpTowardNextLevel)} / {Math.floor(user.Progression.XpNeededForNextLevel)}</span>
        </div>
        {getNewXp()}
      </div>
      <span className='xpBarLevelIndicator' style={{color: getLevelColor(user.Progression.Level + 1)}}>{user.Progression.Level + 1}</span>
    </div>
    
  );
});

class NavButtons extends React.Component {
  constructor(props) {
    super(props);
  }
  handleProfileClick(event) {
    const location = `${window.location.origin}/user/${this.props.user.UserId}`;
    if (event.button == 0) {
      window.location = location;
      return;
    }
    if (event.button == 1) {
      window.open(location);
    }
  }
  handleReqClick(event) {
    const location = window.location.origin + '/req';
    if (event.button == 0) {
      window.location = location;
      return;
    }
    if (event.button == 1) {
      window.open(location);
    }
  }
  render() {
    return (
      <div id='navButtonsContainer'>
        <Link to={'/user/' + this.props.user.UserId}>
          <button id='profileButton'>{this.props.user.Username}</button>
        </Link>
        <Link to='/req'>
          <button id='requestsButton'>!REQ</button>
        </Link>
      </div>
    );
  }
}

function BackpackOpener(props) {
  const getTextClassName = () => {
    if (user.Items.ItemCount >= user.Items.StorageSpace)
      return 'redText';
    return '';
  }
  return (
    <div id='backpackOpenerContainer' onClick={() => props.toggle('backpack')}>
      <svg id='backpackIcon' viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M16.112 20.985h-8.217c-1.912-.062 8.217 0 8.217 0m-7.029-18.69c.29-1.242 1.408-2.275 2.904-2.295 1.454 0 2.631 1.002 2.934 2.308 2.108.516 4.063 1.824 4.722 4.939.28 1.321.539 2.995.754 4.756.8.043 1.873.262 2.107 1.585.317 1.793.496 4.021.496 5.325 0 1.646-1.128 2.053-2.001 2.122-.048 1.708-.737 2.902-2.999 2.948-1.632.033-3.812.008-5.996 0-2.185.008-4.364.033-5.996 0-2.263-.046-2.951-1.24-3-2.948-.873-.067-2.008-.471-2.008-2.121 0-1.307.18-3.54.496-5.326.234-1.327 1.314-1.544 2.114-1.585.213-1.768.466-3.439.733-4.735.649-3.154 2.59-4.465 4.74-4.973m9.917 15.693c-.012 2.008-.987 2.939-2.888 2.997-2.739.026-5.478.028-8.217 0-1.912-.062-2.882-1.188-2.895-2.997v-3.997h14v3.997zm-8-2.998v2.998c0 .553.449.999 1 .999.552 0 1-.447 1-.999v-2.998h5v2.995c0 1.438-.547 1.963-1.908 2-2.725.026-5.451.028-8.176 0-1.21-.035-1.862-.57-1.916-1.889-.024-1.035 0-2.071 0-3.106h5zm.459-9.71c-1.876.104-2.858.83-3.466 2.298-.489 1.182-.648 2.649-.729 3.915-.061.623-.953.625-.999-.019.014-.657.163-2.022.401-3.012.435-1.81 1.27-3.278 3.055-3.879 1.25-.422 3.247-.414 4.462-.037 1.92.592 2.786 2.167 3.21 4.132.2.93.323 2.155.336 2.826-.049.612-.886.648-.994.055-.101-1-.091-1.99-.485-3.325-.607-2.063-1.718-2.86-3.775-2.959l.692 2.385c.166.746-.405 1.454-1.167 1.454-.749 0-1.321-.685-1.172-1.429l.631-2.405zm.541 2.043c.33 0 .597.267.597.597 0 .329-.267.597-.597.597-.33 0-.597-.268-.597-.597 0-.33.267-.597.597-.597m1.791-5.221c-.33-.654-.994-1.102-1.797-1.102-.822.011-1.462.465-1.781 1.096 1.067-.128 2.46-.135 3.578.006"/></svg>
      <span className={getTextClassName()}>{user.Items.ItemCount} / {user.Items.StorageSpace}</span>
    </div>
  );
}

function SubPointButton(props) {
  return (
    <div className='currencyButtonContainer subPoint'>
      <button onClick={() => props.toggle('sub-point-upgrades')}>
        <img src={subPoint} className={'currencyButtonImage' + props.subPointAmount > 0 ? ' dead' : ' alive'} />
      </button>
      <span>{props.subPointAmount > 0 ? props.subPointAmount : ''}</span>
    </div>
  );
}

function GoldenBananaButton(props) {
  return (
    <div className='currencyButtonContainer goldenBanana'>
      <button onClick={() => props.toggle('golden-banana-upgrades')}>
        <img src={goldenBanana} style={{filter: `grayscale(${props.goldenBananaAmount > 0 ? '0' : '100'}%)`}}/>
      </button>
      <span>{props.goldenBananaAmount > 0 ? props.goldenBananaAmount : ''}</span>
    </div>
  );
}

function TwitchView(props) {
  const twitchView = useRef(null);
  const margin = 10;
  useLayoutEffect(() => {
    let embedded;
    const maxWidth = window.innerWidth - margin * 2;
    const aspectRatio = 16 / 9;
    let height = window.innerHeight / 2;
    let width = twitchView.current.offsetWidth - margin;
    if (height * aspectRatio > maxWidth) {
      //vert monitor/mobile
      width = maxWidth;
      height = width * aspectRatio;
    }
    else {
      height = twitchView.current.offsetHeight;
    }
    const script = document.createElement('script');
    script.src = 'https://embed.twitch.tv/embed/v1.js';
    script.async = true;
    script.onload = () => {
      embedded = new window.Twitch.Embed('twitchView', {
        width: width,
        height: height,
        channel: 'yoyoyopo5',
      });
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div id='twitchViewTopContainer'>
      <button onClick={props.toggle}>
        <span>{'<'}</span>
        <span>{'<'}</span>
        <span>{'<'}</span>
      </button>
      <div ref={twitchView} id='twitchViewContainer'>
        <div id='twitchView'></div>
      </div>
    </div>
  );
}

function TwitchViewButton(props) {
  return (
    <div className='twitchViewButtonContainer'>
      <button onClick={props.click}>
        <img src={twitchSmall}></img>
      </button>
    </div>
  );
}
//#endregion Header

//#region GoldenBananaUpgrades
function GoldenBananaUpgradesWindow(props) {
  const availableUpgrades = Object.values(props.upgrades.GoldenBananaUpgrades);
  return (
    <div id='goldenBananaUpgradesWindow'>
      {availableUpgrades.map(upgrade => GoldenBananaUpgradeButton(upgrade))}
      <GoldenBananaRespecButton cost={props.upgrades.RespecCost} totalAllocated={props.upgrades.GoldenBananasAllocated}/>
    </div>  
  );
}

function GoldenBananaUpgradeButton(props) {
  const holdTimeout = useRef(null);
  const holdInterval = useRef(null);
  const [playGrowAnimation, setPlayGrowAnimation] = useState(true);
  setTimeout(() => { setPlayGrowAnimation(false) }, 500 );
  const getImageSrc = () => {
    return bananaTreeIcon;
  }
  const getMultiplierDisplay = (multiplier) => {
    switch(props.Name) {
      case 'banana-click-bonus':
        return '+' + multiplier;
      case 'banana-click-token':
        return (multiplier * 100).toFixed(3) + '%';
      case 'banana-tree-cash-chance':
        return (multiplier * 100).toFixed(3) + '%';
      case 'banana-tree-drop-rate':
        return (multiplier / 1000).toFixed(3) + 's';
      case 'banana-tree-lifespan':
        return (multiplier / 60).toFixed(2) + 'm';
      case 'golden-banana-tree-chance':
        return (multiplier * 100).toFixed(3) + '%';
      default:
        return multiplier;
    }
  }
  const handleMouseDown = () => {
    holdTimeout.current = setTimeout(() => {
      holdInterval.current = setInterval(() => {
        sendBuy();
      }, 100);
    }, 1000);
  }
  const handleMouseUp = () => {
    clearTimeout(holdTimeout.current);
    holdTimeout.current = null;
    if (holdInterval.current == null) {
      sendBuy();
      return;
    }
    if (holdInterval.current != null) {
      clearInterval(holdInterval.current);
      holdInterval.current = null;
    }
  }
  const handleMouseLeave = () => {
    if (holdTimeout.current != null) {
      clearTimeout(holdTimeout.current);
      holdTimeout.current = null;
    }
    if (holdInterval.current != null) {
      clearInterval(holdInterval.current);
      holdInterval.current = null;
    }
  }
  const sendBuy = () => {
    siWebSocket.sendCommand({
      type: 'golden-banana-upgrade',
      upgradeName: props.Name
    });
  }
  return (
    <div key={generateKey()} className='goldenBananaUpgradeButtonContainer'>
      <button onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp}>
        <div className='goldenBananaUpgradeButtonContents'>
          <img src={getImageSrc()} style={{scale: Math.min(props.Amount + 50, 100) + '%'}} className={ playGrowAnimation ? 'goldenBananaUpgradeButtonImage growIn' : 'goldenBananaUpgradeButtonImage' }/>
          <div className='nextMultiplierAmountDisplay'>
            <h1>{getMultiplierDisplay(props.NextMultiplier)}</h1>
          </div>
          <div className='multiplierAmountDisplay'>
            <h1>{getMultiplierDisplay(props.Multiplier)}</h1>
            <h2>{props.Amount}</h2>
          </div>
          <h1 className='goldenBananaUpgradeName'>{props.UpgradeName}</h1>
        </div>
      </button>
      <div className='goldenBananaUpgradeButtonHoverable'>
          <p>{props.Description}</p>
      </div>
    </div>
  );
}

function GoldenBananaRespecButton(props) {
  const gainAmount = props.totalAllocated - props.cost.Amount;
  const textLength = (gainAmount).toString().length;
  const textSize = Math.min(4 / textLength, 2);
  const gainText = gainAmount > 9999 ? (gainAmount / 1000).toFixed(2) + 'K' : gainAmount;
  const handleClick = () => {
    const popupId = crypto.randomUUID();
    togglePopup({ id: popupId, renderPopup: () => GoldenBananaRespecPopup({ id: popupId, cost: props.cost, totalAllocated: props.totalAllocated }) });
  }
  return (
    <div id='goldenBananaRespecButtonContainer'>
      <button onClick={handleClick}>
        <img id='respecGoldenBanana' src={goldenBanana}></img>
        <div id='respecArrowsContainer'>
          <img className='respecArrow' src={greenCircleArrow}></img>
          <img className='respecArrow' src={greenCircleArrow}></img>
          <img className='respecArrow' src={greenCircleArrow}></img>
        </div>
        <div className='respecTextGlow'></div>
        <h2 style={{fontSize: textSize + 'em'}}>{gainText}</h2>
      </button>
    </div>
  );
}

function GoldenBananaRespecPopup(props) {
  const cancel = () => {
    togglePopup(props.id);
  }
  const send = () => {
    siWebSocket.sendCommand({
      type: 'gb-upgrade-respec'
    });
    togglePopup(props.id);
  }
  return (
    <div className='popupWindowContainer'>
      <h1>Respec Upgrades?</h1>
      <div className='goldenBananaRespecPopupTextContainer'>
        <div>
          <h2>Total Allocated</h2>
          <h1 style={{color: '#ffee6e'}}>{props.totalAllocated}</h1>
        </div>
        <span>-</span>
        <div>
          <h2>Respec Cost</h2>
          <h1 style={{color: '#f74d4d'}}>{props.cost.Amount}</h1>
        </div>
        <span>=</span>
        <div>
          <h2>Amount Regained</h2>
          <h1 style={{color: '#3fb514'}}>{props.totalAllocated - props.cost.Amount}</h1>
        </div>
      </div>
      <div className='popupWindowButtonContainer'>
        <button className='popupWindowButton cancel' onClick={cancel}>Cancel</button>
        <button className='popupWindowButton send' onClick={send}>Respec</button>
      </div>
    </div>
  );
}
//#endregion GoldenBananaUpgrades

//#region SubPointUpgrades

function SubPointUpgradesWindow(props) {
  const baseKey = generateKey();
  const stars = Array.from({length:50}, (_, index) => {
    return <div key={'star' + (baseKey + index)} className='backgroundStar'/>
  });
  return (
    <div id='subPointUpgradesWindow'>
      {stars}
      <div id='subPointUpgradeButtonsContainer'>
        {props.upgrades.map(upgrade => SubPointUpgradeButton({upgrade: upgrade, index: props.upgrades.indexOf(upgrade)}))}
      </div>
    </div>
  );
}

function SubPointUpgradeButton(props) {
  const image = require(`./media/icons/sub-point-upgrades/sp-${props.upgrade.Name}.svg`);
  const [progressAmount, setProgressAmount] = useState(100);
  const [progressInterval, setProgressInterval] = useState(null);
  const mouseDown = () => {
    const interval = setInterval(() => {
      setProgressAmount((current) => {
        return current - 1;
    });}, 16);
    setProgressInterval(interval);
  }
  const mouseUp = () => {
    clearInterval(progressInterval);

    if (progressAmount <= 0) {
      siWebSocket.sendCommand({
        type: 'sub-point-upgrade',
        upgradeName: props.upgrade.Name
      });
    }

    setProgressAmount(100);
  }
  const mouseOut = () => {
    clearInterval(progressInterval);
    setProgressAmount(100);
  }
  const getMultiplierText = (type) => {
    if (props.upgrade.Name === 'golden-rain-chance') {
      if (type === 'next') {
        return '1 / ' + (1 / props.upgrade.NextMultiplier / 3600).toFixed(2) + 'hr';
      }
      return '1 / ' + (1 / props.upgrade.Multiplier / 3600).toFixed(2) + 'hr';
    }
    if (type === 'next') {
      return (props.upgrade.NextMultiplier * 100).toFixed(2) + '%';
    }
    return (props.upgrade.Multiplier * 100).toFixed(2) + '%';
  }
  const renderOptionalElements = () => {
    if (user.Currencies.SubPoints <= 0) { return; }
    return (
      <div style={{position: 'absolute', width: '100%', height: '100%', top: '0%', left: '0%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <span className='nextMultiplierText'>{getMultiplierText('next')}</span>
        <svg className='upArrowSvg' viewBox="0 0 24 24">
          <defs>
            <linearGradient id={'progressArrowGradient' + props.index} x1="0" x2="0" y1="0" y2="1">
              <stop offset={progressAmount.toString() + '%'} stopColor="#000"/>
              <stop offset={progressAmount.toString() + '%'} stopColor='#4CAF50'/>
            </linearGradient>
          </defs>
          <path fill={`url(#progressArrowGradient${props.index})`} d="m9.001 10.978h-3.251c-.412 0-.75-.335-.75-.752 0-.188.071-.375.206-.518 1.685-1.775 4.692-4.945 6.069-6.396.189-.2.452-.312.725-.312.274 0 .536.112.725.312 1.377 1.451 4.385 4.621 6.068 6.396.136.143.207.33.207.518 0 .417-.337.752-.75.752h-3.251v9.02c0 .531-.47 1.002-1 1.002h-3.998c-.53 0-1-.471-1-1.002z"/>
        </svg>
      </div>
    );
  }
  return (
    <button key={props.upgrade.Name} className='subUpgradeButton' onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseLeave={mouseOut}>
      <img src={image}/>
      <span>{props.upgrade.DisplayName}</span>
      <span className='amountText'>{props.upgrade.Redemptions.length}</span>
      {renderOptionalElements()}
      <span className='multiplierText'>{getMultiplierText('multiplier')}</span>
      <div className='tooltip'>
        <p>{props.upgrade.Description}</p>
      </div>
    </button>
  );
}

//#endregion SubPointUpgrades

//#region Backpack
function BackpackWindow(props) {
  const [autoRoll, setAutoRoll] = useState({ type: 'none', interval: null });
  const setAutoRollType = (type) => {
    if (autoRoll.interval != null) {
      clearInterval(autoRoll.interval);
    }
    if (type === autoRoll.type) {
      setAutoRoll({ type: 'none', interval: null });
      return;
    }
    const interval = setInterval(() => {
      if (user.Items.ItemCount >= user.Items.StorageSpace) {
        setAutoRollType('none');
        return;
      }
      siWebSocket.sendRoll(type);
    }, 1500);
    setAutoRoll({ type: type, interval: interval });
  }
  return (
    <div id='backpackWindow'>
      <p style={{margin: 'auto', fontSize: 'xx-large', width: 'fit-content'}}>INVENTORY</p>
      <p style={{margin: 'auto', fontSize: 'larger', width: 'fit-content'}}>Double click to sell</p>
      <div className='linebreak'/>
      <div id='backpackFunctionButtonsContainer'>
      <div className='backpackFunctionButtonContainer'>
          <svg fill={user.AutoSortItems ? '#F2AD37' : '#464646'} className='backpackFunctionSvg' id='sortBackpackFunctionSvg' viewBox="0 0 24 24" onClick={() => siWebSocket.sendCommand({ type: 'auto-sort-items' })}>
            <path d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-4h-8v-2h8v2zm2 2h-10v2h10v-2zm-4-8h-6v2h6v-2zm-2-4h-4v2h4v-2zm-2-4h-2v2h2v-2z"/>
          </svg>
        </div>
        <div className='backpackFunctionButtonContainer'>
          <svg fill={user.IsSellingDuplicates ? '#F2AD37' : '#464646'} className='backpackFunctionSvg' id='sellDuplicatesBackpackFunctionSvg' viewBox="0 0 24 24" onClick={() => siWebSocket.sendCommand({ type: 'sell-duplicate-items' })}>
            <path d="M18,6V0H0V18H6v6H24V6ZM6,16H2V2H16V6H6Zm16,6H8V8H22Z M15.76,18.39v.7h-1.5V18.4a2.2,2.2,0,0,1-2-2.24H14.2a.7.7,0,0,0,.79.68c.38,0,.75-.2.75-.54s-.51-.54-1.15-.7c-1-.24-2.21-.54-2.21-2.07a2,2,0,0,1,1.82-2v-.66h1.49v.67a2,2,0,0,1,1.78,2.08H15.61c0-.39-.31-.58-.7-.58s-.65.14-.65.43.5.5,1.14.67c1,.25,2.28.58,2.28,2.11A2.09,2.09,0,0,1,15.76,18.39Z"/>
          </svg>
        </div>
      </div>
      <BackpackSection title="THROWABLES" type='throwable' autoRollType={autoRoll.type} setAutoRoll={setAutoRollType} rollPrice={prices.RollPrices.ThrowablePrice} sectionImage={rocks} items={user.Items.Throwables.map(throwableName => throwables.filter(throwable => throwable.name === throwableName).map(throwable => BackpackButton({ type: 'throwable', image: throwable.image, itemName: throwable.name, text: null, rarity: throwable.rarity, sellPrice: prices.SellPrices.Throwables.find(sp => sp.Rarity === throwable.rarity).Price })))}/>
      <BackpackSection title='SOUNDS' type='sound' autoRollType={autoRoll.type} setAutoRoll={setAutoRollType} rollPrice={prices.RollPrices.SoundPrice} sectionImage={audio} items={user.Items.Sounds.map(soundName => sounds.filter(sound => sound.name === soundName).map(sound => BackpackButton({ type: 'sound', image: null, itemName: sound.name, text: sound.friendlyName, rarity: sound.rarity, sellPrice: prices.SellPrices.Sounds.find(sp => sp.Rarity === sound.rarity).Price })))}/>
      <BackpackSection title='CLIPS' type='video' autoRollType={autoRoll.type} setAutoRoll={setAutoRollType} rollPrice={prices.RollPrices.VideoPrice} sectionImage={video1} items={user.Items.Videos.map(videoName => videos.filter(video => video.name === videoName).map(video => BackpackButton({ type: 'video', image: video.image, itemName: video.name, text: null, rarity: video.rarity, sellPrice: prices.SellPrices.Videos.find(sp => sp.Rarity === video.rarity).Price })))}/>
      <BackpackSection title='MANIFESTS' type='greenscreen' autoRollType={autoRoll.type} setAutoRoll={setAutoRollType} rollPrice={prices.RollPrices.GreenscreenPrice} sectionImage={magicWandIcon} items={user.Items.Greenscreens.map(greenscreenName => greenscreens.filter(greenscreen => greenscreen.name === greenscreenName).map(greenscreen => BackpackButton({ type: 'greenscreen', image: greenscreen.image, itemName: greenscreen.name, text: null, rarity: greenscreen.rarity, sellPrice: prices.SellPrices.Greenscreens.find(sp => sp.Rarity === greenscreen.rarity).Price })))}/>
      <BackpackSection title='VIDEOS' type='scene' autoRollType={autoRoll.type} setAutoRoll={setAutoRollType} rollPrice={prices.RollPrices.ScenePrice} sectionImage={sceneIcon} items={user.Items.Scenes.map(sceneName => scenes.filter(scene => scene.name.toLowerCase() === sceneName).map(scene => BackpackButton({ type: 'scene', image: scene.image, itemName: scene.name, text: null, rarity: scene.rarity, sellPrice: prices.SellPrices.Scenes.find(sp => sp.Rarity === scene.rarity).Price })))}/>
    </div>
  );
}

function BackpackSection(props) {
  return (
    <div className='backpackSection'>
      <div style={{display: 'flex', alignItems: 'center', maxWidth: '100%'}}>
        <img className='backpackSectionImage' src={props.sectionImage}/>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className='divider' style={{alignSelf: 'stretch', marginTop: '20px', marginBottom: '20px'}}/>
          <div className='backpackButtonsContainer'>
            <BackpackRollButton autoRollType={props.autoRollType} setAutoRoll={props.setAutoRoll} type={props.type} price={props.rollPrice}/>
            {props.items}
          </div>
        </div>
      </div>
    </div>
  );
}

function SellPopup(props) {
  const cancel = () => {
    togglePopup(props.id);
  }
  const send = () => {
    siWebSocket.sendSell(props.item.itemName);
    togglePopup(props.id);
  }
  const renderItem = () => {
    if (props.item.image == null) { 
      return <p className='sellPopupItemText'>{props.item.text}</p>
    }
    return <img className="sellPopupItemImage" src={props.item.image}></img>;
  }
  return (
    <div className='popupWindowContainer'>
      <h1 className='popupWindowTitle'>Sell item?</h1>
      <div style={{backgroundColor: colors[props.item.rarity.toLowerCase()]}} className='sellPopupItemContainer'>
        {renderItem()}
        <div className='sellPopupItemPrice' style={{borderColor: lightColors[props.item.rarity.toLowerCase()], backgroundColor: colors[props.item.rarity.toLowerCase()]}}>
          <CurrencyAmountDisplay currencyAmount={props.item.sellPrice}/>
        </div>
      </div>
      <div className='popupWindowButtonContainer'>
        <button className='popupWindowButton cancel' onClick={() => cancel()}>
          CANCEL
        </button>
        <button className='popupWindowButton send' onClick={() => send()}>
          SELL
        </button>
      </div>
    </div>
  );
}

function BackpackButton(props) {
  const handleClick = (event) => {
    if (event.detail == 1) {

    }
    if (event.detail % 2 == 0) {
      if (props.rarity !== 'Epic' && props.rarity !== 'Legendary') {
        siWebSocket.sendSell(props.itemName);
        return;
      }
      const popupId = crypto.randomUUID();
      togglePopup({ id: popupId, renderPopup: () => SellPopup({ id: popupId, togglePopup: togglePopup, item: props }) });
    }
  }
  const sellValue = () => {
    return (
      <div className='hoverableButtonPrice' style={{borderColor: lightColors[props.rarity.toLowerCase()], backgroundColor: colors[props.rarity.toLowerCase()]}}>
        <CurrencyAmountDisplay currencyAmount={props.sellPrice}/>
      </div>
    );
  }
  const renderImage = () => {
    if (props.image == null) { return; }
    return <img className="buttonImage" src={props.image}></img>;
  }
  return (
    <button className='backpackButton' onClick={handleClick} style={{backgroundColor: colors[props.rarity.toLowerCase()]}}>
      <div className='backpackButtonContentContainer'>
        {renderImage()}
        <span className='buttonText'>{props.text}</span>
      </div>
      {sellValue()}
      <div className='backpackButtonTooltip'>
        <span>{props.itemName}</span>
      </div>
    </button>
  );
}

function BackpackRollButton(props) {
  const handleClick = (event) => {
    if (event.detail % 2 === 0) {
      props.setAutoRoll(props.type);
      return;
    }
    siWebSocket.sendRoll(props.type);
  }
  const rollPrice = () => {
    return (
      <div className='hoverableButtonPrice' style={{borderColor: lightColors['buttonNeutral'], backgroundColor: darkColors['buttonNeutral']}}>
        <CurrencyAmountDisplay currencyAmount={props.price}/>
      </div>
    );
  }
  return (
    <button className='backpackButton' style={{backgroundColor: props.autoRollType === props.type ? colors['legendary'] : darkColors['buttonNeutral']}} onClick={handleClick}>
      <div className='backpackButtonContentContainer'>
        <img className='backpackRollButtonImage' src={plusIcon}/>
      </div>
      {rollPrice()}
    </button>
  );
}
//#endregion Backpack

//#region RewardSections
function RewardsContainer(props) {
  const [activeMenu, setActiveMenu] = useState('none');
  const scrollRef = useRef(null);
  const handleClick = type => {
    //Check for render menu
    if (activeMenu === type) {
      setActiveMenu('none');
    }
    else {
      setActiveMenu(type);
      if (renderMenu(type) != undefined) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    //Reward-specific handling
    switch(type) {
      case 'firework':
        siWebSocket.sendFirework();
        break;
      case 'youtube':
        const popupId = crypto.randomUUID();
        togglePopup({ id: popupId, renderPopup: () => YoutubePopup({ id: popupId, togglePopup: props.togglePopup }) });
        break;
      default:
        break;
    }
  }
  const getPrice = (basePrice, type) => {
    let multiplier = 1;
    if (user.ActionIntervals.TrackedActions[type] != undefined) {
      multiplier = user.ActionIntervals.TrackedActions[type].PriceMultiplier;
    }
    const priceAmount = Math.floor(basePrice.Amount * multiplier);
    return {
      Type: basePrice.Type,
      Amount: priceAmount
    }
  }
  const renderMenu = (type) => {
    switch (type) {
      case 'basic-scene':
        return <BasicSceneMenu price={getPrice(prices.UsePrices.SceneSwap, "basic-scene")}/>;
      case 'throwable':
        return <ThrowableMenu price={getPrice(prices.UsePrices.Throwable, "throwable")}/>;
      case 'sound':
        return <SoundMenu price={getPrice(prices.UsePrices.Sound, "sound")}/>;
      case 'video':
        return <VideoMenu price={getPrice(prices.UsePrices.Video, 'video')}/>;
      case 'greenscreen':
        return <GreenscreenMenu price={getPrice(prices.UsePrices.Greenscreen, 'greenscreen')}/>;
      case 'scene':
        return <SceneMenu price={getPrice(prices.UsePrices.Scene, 'scene')}/>;
      case 'key-press':
        return <KeyMenu price={getPrice(prices.UsePrices.BossKey, 'key-press')}/>;
      default:
        return;
    }
  }
  const renderHeading = rarity => {
    switch(rarity) {
      case 'common':
        return <RewardsContainerHeading image={banana} amount={user.Currencies.Bananas} delta={props.currencyDelta} deltaKey={props.deltaKey} description={<h1>{'>>'} Earn <span style={{color: lightColors['common']}}>bananas</span> over time when in the Twitch chat or on the website.</h1>}/>;
      case 'uncommon':
        return <RewardsContainerHeading image={token} amount={user.Currencies.McTokens} delta={props.currencyDelta} deltaKey={props.deltaKey} description={<h1>{'>>'} Earn <span style={{color: lightColors['uncommon']}}>McTokens</span> by redeeming cash out channel point rewards on Twitch.</h1>}/>;
      case 'rare':
        return <RewardsContainerHeading image={cash} amount={user.Currencies.Cash} delta={props.currencyDelta} deltaKey={props.deltaKey} description={<h1>{'>>'} Earn <span style={{color: lightColors['rare']}}>cash</span> by participating in quests and roll calls.</h1>}/>;
      case 'epic':
        return <RewardsContainerHeading image={bits} amount={user.Currencies.Bits} delta={props.currencyDelta} deltaKey={props.deltaKey} description={<h1>{'>>'} Earn <span style={{color: lightColors['epic']}}>bits</span> by using Twitch bits in the chat.</h1>}/>;
      default:
        return;
    }
  }
  const renderRewardButtons = rarity => {
    switch(rarity) {
      case 'common':
        return (
          <div className='rewardButtonsContainer'>
            <RewardButton rarity='common' click={() => handleClick('basic-scene')} type='basic-scene' price={getPrice(prices.UsePrices.SceneSwap, "basic-scene")} image={sceneIcon} text='SCENE'/>
            <RewardButton rarity='common' click={() => handleClick('throwable')} type='throwable' price={getPrice(prices.UsePrices.Throwable, "throwable")} rollPrice={{type: token, amount: prices.RollPrices.ThrowablePrice}} sellPrices={{type: token, amount: prices.SellPrices.ThrowablePrices}} image={rocks} text='THROW'/>
            <RewardButton rarity='common' click={() => handleClick('firework')} type='firework' price={getPrice(prices.UsePrices.Firework, "firework")} image={fireworkIcon} text='FIREWORK'/>
            <RewardButton rarity='common' click={() => handleClick('sound')} type='sound' price={getPrice(prices.UsePrices.Sound, "sound")} rollPrice={{type: token, amount: prices.RollPrices.SoundPrice}} sellPrices={{type: token, amount: prices.SellPrices.SoundPrices}} image={audio} text='SOUND'/>
          </div>
        );
      case 'uncommon':
        return (
          <div className='rewardButtonsContainer'>
            <RewardButton rarity='uncommon' click={() => handleClick('video')} type='video' price={getPrice(prices.UsePrices.Video, 'video')} image={video1} text='CLIP'/>
            <RewardButton rarity='uncommon' click={() => handleClick('greenscreen')} type='greenscreen' price={getPrice(prices.UsePrices.Greenscreen, 'greenscreen')} rollPrice={{type: cash, amount: prices.RollPrices.GreenscreenPrice}} image={magicWandIcon} text='MANIFEST'/>
            <RewardButton rarity='uncommon' click={() => handleClick('scene')} type='scene' price={getPrice(prices.UsePrices.Scene, 'scene')} image={video1} rollPrice={{type: cash, amount: prices.RollPrices.ScenePrice}} text='VIDEO'/>
            <RewardButton rarity='uncommon' click={() => handleClick('key-press')} type='key-press' price={getPrice(prices.UsePrices.BossKey, 'key-press')} image={bossKeyIcon} text='PRESS KEY'/>
          </div>
        );
      case 'rare':
        return (
          <div className='rewardButtonsContainer'>
            <RewardButton rarity='rare' type='youtube' click={() => handleClick('youtube')} price={getPrice(prices.UsePrices.Youtube, 'youtube')} image={youtubeIcon} text='YOUTUBE'/>
          </div>
        );
      case 'epic':
        return (
          <div className='rewardButtonsContainer'>

          </div>
        );
      default:
        return;
    }
  }
  return (
    <div>
      <div className={'currencyRewardsContainer ' + props.rarity}>
        {renderHeading(props.rarity)}
        {renderRewardButtons(props.rarity)}
      </div>
      <div ref={scrollRef}/>
      {renderMenu(activeMenu)}
    </div>
  );
}

function YoutubePopup(props) {
  const [input, setInput] = useState('');
  const cancel = () => {
    togglePopup(props.id);
  }
  const send = () => {
    siWebSocket.sendYoutube(input);
    togglePopup(props.id);
  }
  return (
    <div className='popupWindowContainer'>
      <div className='youtubeInputContainer'>
        <h1 style={{margin: '0'}}>INPUT YOUTUBE LINK:</h1>
        <textarea className='popupInput' value={input} onChange={(event) => {
          setInput(event.target.value);
        }}/>
        <div style={{marginBottom: '10px'}}>
          <p className='popupWindowNote'>Videos are limited to 2 minutes maximum.</p>
          <p className='popupWindowNote'>Please remember to follow Rule #1</p>
        </div>
      </div>
      <div className='popupWindowButtonContainer'>
        <button className='popupWindowButton cancel' onClick={() => cancel()}>
          CANCEL
        </button>
        <button className='popupWindowButton send' onClick={() => send()}>
          OK
        </button>
      </div>
    </div>
  );
}

const RewardsContainerHeading = React.memo(props => {
  const amountText = () => {
    if (props.amount < 10_000) {
      return props.amount.toString();
    }
    if (props.amount < 1_000_000) {
      return (props.amount / 1000).toFixed(2) + 'K';
    }
    return (props.amount / 1_000_000).toFixed(3) + 'M';
  }
  const getColor = () => {
    if (props.delta > 0) {
      return colors.textGreen;
    }
    if (props.delta < 0) {
      return colors.textRed;
    }
    return '#FFF';
  }
  return (
    <div className='rewardsContainerHeadingContainer'>
      <div className='rewardsContainerHeadingCurrencyAmountContainer'>
        <img src={props.image}></img>
        <span className='currencyAmountText' key={props.deltaKey} style={{color: getColor()}}>{amountText()}</span>
      </div>
      <div className='rewardsContainerHeadingTooltip'>
        {props.description}
      </div>
    </div>
  );
});
//#endregion RewardSections

class RewardButton extends React.Component {
  constructor(props){
    super(props);
    this.reward = rewardsJson.filter(reward => reward.Name === this.props.type)[0];
  }
  getBackgroundColor() {
    return colors[this.props.rarity];
  }
  isEnabled() {
    if (this.props.enabled === false) { return false; }
    if (this.reward.Level > user.Stats.Level ) { return false; }
    return true;
  }
  getImage() {
    if (!this.isEnabled()) { return lockIcon; }
    return this.props.image;
  }
  getLevelText() {
    if (this.isEnabled()) { return; }
    return (
      <span className='rewardButtonLevel'>
          Lvl. {this.reward.Level}
      </span>
    );
  }
  render(){
    return (
      <div className='rewardButtonContainer'>
        <button className='rewardButton' style={{backgroundColor: this.getBackgroundColor()}} disabled={!this.isEnabled()} onClick={this.props.click}>
          <div className='rewardPriceDisplayContainer'>
            <CurrencyAmountDisplay currencyAmount={this.props.price} />
          </div>
          <div className='rewardButtonContentContainer'>
            <img className='rewardButtonImage' src={this.getImage()}></img>
            <span className='rewardButtonText'> {this.props.text} </span>
            {this.getLevelText()}
          </div>
        </button>
      </div>
    );
  }
}

function CurrencyAmountDisplay(props) {
  //Takes in a CurrencyAmount obj as single prop
  const getIcon = (currencyType) => {
    switch (currencyType) {
      case 'Bananas':
        return banana;
      case 'McTokens':
        return token;
      case 'Cash':
        return cash;
      case 'Bits':
        return bits;
      case 'SubPoints':
        return subPoint;
      case 'GoldenBananas':
        return goldenBanana;
      default:
        return null;
    }
  }
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0}}>
      <img src={getIcon(props.currencyAmount.Type)} className='rewardPriceImage'></img>
      <span className='rewardPriceText'> {props.currencyAmount.Amount} </span>
    </div>
  );
}

//#region RewardMenus
function BasicSceneMenu(props) {
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['common']}}>
      {basicScenes.map((scene => RewardMenuButton({ key: scene.name, type: 'basic-scene', click: () => siWebSocket.sendCommand({type: 'basic-scene', name: scene.name}), rarity: 'common', text: scene.text, image: sceneIcon, usePrice: props.price  })))}
    </div>
  );
}

function ThrowableMenu(props) {
  const cleanedThrowables = [];
  user.Items.Throwables.forEach(throwableName => {
    if (cleanedThrowables.indexOf(throwableName) == -1) {
      cleanedThrowables.push(throwableName);
    }
  });
  const shuffleClick = () => {
    if (cleanedThrowables.length == 0) { return; }
    siWebSocket.sendThrow(getRandom(cleanedThrowables));
  }
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['common']}}>
      <RewardShuffleButton click={shuffleClick}/>
      {cleanedThrowables.map(throwableName => throwables
        .filter(throwable => throwable.name === throwableName)
        .map(throwable => 
          <RewardMenuButton key = {throwable.name} type='throw' click={() => siWebSocket.sendThrow(throwable.name)} rarity = {throwable.rarity} text = {null} image = {throwable.image} usePrice = {props.price}  />
      ))}
    </div>
  );
}

function SoundMenu(props) {
  const cleanedSounds = [];
  user.Items.Sounds.forEach(soundName => {
    if (cleanedSounds.indexOf(soundName) == -1) {
      cleanedSounds.push(soundName);
    }
  });
  const shuffleClick = () => {
    if (cleanedSounds.length == 0) { return; }
    siWebSocket.sendSound(getRandom(cleanedSounds));
  }
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['common']}}>
      <RewardShuffleButton click={shuffleClick}/>
      {cleanedSounds.map(soundName => sounds.filter(sound => sound.name === soundName)
        .map(sound => 
        <RewardMenuButton useCharge={true} key={sound.name} type='sound' click={charge => siWebSocket.sendSound(sound.name, charge)} rarity={sound.rarity} text={ sound.friendlyName} image={null} usePrice={props.price} />  
      ))}
    </div>
  );
}

function VideoMenu(props) {
  const cleanedVideos = [];
  user.Items.Videos.forEach(videoName => {
    if (cleanedVideos.indexOf(videoName) == -1) {
      cleanedVideos.push(videoName);
    }
  });
  const shuffleClick = () => {
    if (cleanedVideos.length == 0) { return; }
    siWebSocket.sendVideo(getRandom(cleanedVideos));
  }
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['uncommon']}}>
      <RewardShuffleButton click={shuffleClick}/>
      {cleanedVideos.map(videoName => videos.filter(video => video.name === videoName)
      .map(video => 
        <RewardMenuButton key= {video.name} type= 'video' click= {() => siWebSocket.sendVideo(video.name)} rarity= {video.rarity} text= {null} image= {video.image} usePrice= {{ Type: props.price.Type, Amount: props.price.Amount * video.length }}  />
      ))}
    </div>
  );
}

function GreenscreenMenu(props) {
  const cleanedGreenscreens = [];
  user.Items.Greenscreens.forEach(greenscreenName => {
    if (cleanedGreenscreens.indexOf(greenscreenName) == -1) {
      cleanedGreenscreens.push(greenscreenName);
    }
  });
  const shuffleClick = () => {
    if (cleanedGreenscreens.length == 0) { return; }
    siWebSocket.sendGs(getRandom(cleanedGreenscreens));
  }
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['uncommon']}}>
      <RewardShuffleButton click={shuffleClick}/>
      {cleanedGreenscreens.map(greenscreenName => greenscreens.filter(greenscreen => greenscreen.name === greenscreenName)
        .map(greenscreen => 
        <RewardMenuButton key= {greenscreen.name} type= 'green-screen' click= {() => siWebSocket.sendGs(greenscreen.name)} rarity= {greenscreen.rarity} text= {null} image= {greenscreen.image} usePrice= {props.price} />
      ))}
    </div>
  );
}

function SceneMenu(props) {
  const cleanedScenes = [];
  user.Items.Scenes.forEach(sceneName => {
    if (cleanedScenes.indexOf(sceneName) == -1) {
      cleanedScenes.push(sceneName);
    }
  });
  const shuffleClick = () => {
    if (cleanedScenes.length == 0) { return; }
    siWebSocket.sendScene(getRandom(cleanedScenes));
  }
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['uncommon']}}>
      <RewardShuffleButton click={shuffleClick}/>
      {cleanedScenes.map(sceneName => scenes.filter(scene => scene.name.toLowerCase() === sceneName).
      map(scene => 
        <RewardMenuButton key= {scene.name} type= 'scene' click= {() => siWebSocket.sendScene(scene.name)} rarity= {scene.rarity} text= {null} image= {scene.image} usePrice= {{ Type: props.price.Type, Amount: props.price.Amount * scene.length}}  />
      ))}
      <RewardMenuButton click={() => siWebSocket.sendCommand({ type: 'cancel-scene' })} usePrice={prices.UsePrices.SceneCancel} rarity={'common'} text='CANCEL VIDEO' image={cancelIcon}/>
    </div>
  );
}

function KeyMenu(props) {
  return (
    <div className='rewardMenu' style={{backgroundColor: darkColors['uncommon']}}>
      <RewardMenuButton key={'BOSS'} text='BOSS' image={null} rarity='uncommon' usePrice={prices.UsePrices.BossKey} click={() => siWebSocket.sendKey('BOSS')}/>
      <RewardMenuButton key={'RANDOM'} text='RANDOM' image={null} rarity='uncommon' usePrice={prices.UsePrices.RandomKey} click={() => siWebSocket.sendKey('RANDOM')}/>
    </div>
  );
}
//#endregion RewardMenus

function RewardMenuButton(props) {
  const [charge, setCharge] = useState(0);
  const [chargeInterval, setChargeInterval] = useState(null);
  const [chargeState, setChargeState] = useState('');
  useEffect(() => {
    if (charge > 99)
      clearInterval(chargeInterval);
  }, [charge])
  const usePriceColor = () => {
    if (user.Currencies[props.usePrice.Type] < props.usePrice.Amount) { return 'buttonRed' }
    return props.rarity.toLowerCase();
  }
  const getChargeMult = () => {
    if (!props.useCharge)
      return 1;
    const coefficient = prices.ChargeCoefficients[props.type];
    return charge * charge * coefficient + 1;
  }
  const usePrice = () => {
    return (
      <div className='hoverableButtonPrice' style={{borderColor: lightColors[usePriceColor()], backgroundColor: colors[usePriceColor()]}}>
        <CurrencyAmountDisplay currencyAmount={{Type: props.usePrice.Type, Amount: Math.floor(props.usePrice.Amount * getChargeMult())}}/>
      </div>
    );
  }
  const renderImage = () => {
    if (props.image == null) { return; }
    return <img className="buttonImage" src={props.image}></img>;
  }
  const renderText = () => {
    if (props.text == null) { return; }
    return <span className='buttonText'>{props.text}</span>;
  }
  const renderCharge = () => {
    if (!props.useCharge)
      return;
    return (
      <div key={props.key + '1'} className='chargeBarContainer'>
        <div key={props.key + '2'} className='chargeBarBackground'>
          <div key={props.key + '3'} className={'chargeBarForeground ' + chargeState}>
          </div>
        </div>
      </div>
    );
  }
  const startCharge = () => {
    if (!props.useCharge)
      return;
    clearInterval(chargeInterval);
    setChargeInterval(setInterval(() => {
      setCharge(charge => charge + 1);
    }, 20));
    setChargeState('charging');
  }
  const stopCharge = () => {
    if (!props.useCharge)
      return;
    clearInterval(chargeInterval);
    setCharge(0);
    setChargeState('');
  }
  return (
    <button key={props.key} className='rewardMenuButton' onMouseDown={startCharge} onClick={() => {props.click(charge); stopCharge();}} style={{backgroundColor: colors[props.rarity.toLowerCase()]}}>
      <div className='backpackButtonContentContainer'>
        {renderImage()}
        {renderText()}
      </div>
      {renderCharge()}
      {usePrice()}
    </button>
  );
}

function RewardShuffleButton(props) {
  return (
    <button className='rewardMenuButton' onClick={props.click} style={{backgroundColor: colors['common']}}>
      <div className='backpackButtonContentContainer'>
        <img className='buttonImage' src={shuffleIcon} style={{width: '90px'}}/>
      </div>
    </button>
  );
}

function PopupsContainer(props) {
  if (props.activePopups == null || props.activePopups.length == 0) {
    return;
  }
  return (
    <div id='popupsContainer'>
      <div id='popupsContainerBackground' onClick={props.closePopups}>

      </div>
      {props.activePopups.map(popup => popup.renderPopup())}
    </div>
  );
}

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isConnectedToWS: false,
      isConnectionError: false,
      isConnectionClosed: false,
      commandRecieved: false,
      isLoggedIn: false,
      user: null,
      currencyDeltas: [],
      deltaKey: generateKey(),
      newXp: null,
      popupOpen: false,
      activePopups: [],
      currentItemRoll: null,
      activeRewardMenu: 'none',
      headerDropdown: 'none',
      isTwitchVideoOpen: false
    };
  }
  componentDidMount(){
    if (this.props.ws.webSocket.readyState) {
      this.webSocketConnected();
    } 
    this.props.ws.webSocket.onopen = () => this.webSocketConnected();
    this.props.ws.webSocket.onclose = () => this.setState({ isConnectedToWS: false, isConnectionClosed: true });
    this.props.ws.webSocket.onerror = () => this.setState({ isConnectedToWS: false, isConnectionError: true });
    togglePopup = this.togglePopup.bind(this);
  }
  webSocketConnected(){
    siWebSocket = this.props.ws;
    siWebSocket.init();
    this.setState({ isConnectedToWS: true, isConnectionClosed: false, isConnectionError: false });
      this.props.ws.webSocket.onmessage = (event) => {
      let parsedMessage = JSON.parse(event.data);
      if (!('Type' in parsedMessage)){
        return;
      }
      console.log(parsedMessage);
      switch(parsedMessage.Type) { //Handle commands from server here
        case 'error':
          console.log(parsedMessage.Message);
          break;
        case 'loggedIn':
          user = parsedMessage.User;
          this.setState({
            isLoggedIn: true,
            user: parsedMessage.User
          })
          this.requestData();
          break;
        case 'user-data':
          this.handleUserUpdate(parsedMessage);
          break;
        case 'prices-update':
          this.handlePricesUpdate(parsedMessage.Prices);
          break;
        case 'item-roll':
          if (this.state.rollTimeout != null){
            clearTimeout(this.state.rollTimeout);
          }
          const timeout = setTimeout(() => this.setState({ currentItemRoll: null }), 2000);
          this.setState({ currentItemRoll: parsedMessage.ItemRoll, rollTimeout: timeout });
          break;
        case 'new-xp':
          this.handleNewXpEvent(parsedMessage.Data);
          break;
        case 'sub-upgrades-update':
          this.setState({ subPointUpgrades: parsedMessage.Upgrades });
          break;
        default:
          break;
      }
    };
    if (this.state.isLoggedIn == false) {
      this.props.ws.checkAuth();
    }
  }
  requestData() {
    this.props.ws.sendCommand({ type: 'prices-request' });
    this.props.ws.sendCommand({ type: 'sub-upgrades-request' });
  }
  handlePricesUpdate(newPrices){
    prices = newPrices;
    this.setState({
      prices: newPrices
    });
  }
  handleUserUpdate(userDataCommand){
    if (userDataCommand.UserDataType === 'User') {
      user = userDataCommand.UserData;
    }
    else {
      const newUser = Object.assign({}, user);
      newUser[userDataCommand.UserDataType] = userDataCommand.UserData;
      user = newUser;
    }
    const currencyDeltas = getCurrencyDeltas(this.state.user, user);
    if (!Object.values(currencyDeltas).every(delta => delta == 0)) {
      this.setState({
        user: user,
        currencyDeltas: currencyDeltas,
        deltaKey: generateKey()
      });
    }
    else {
      this.setState({
        user: user
      });
    }
  }
  handleNewXpEvent(xpEvent){
    const indexedXpEvent = {
      FlavorText: xpEvent.FlavorText,
      Amount: xpEvent.Amount,
      Id: window.crypto.randomUUID()
    };
    this.setState({ newXp: indexedXpEvent });
  }
  togglePopup(popup) {
    let newPopups = Array.from(this.state.activePopups);
    if (popup?.renderPopup == undefined) {
      const foundPopup = this.state.activePopups.find(activePopup => activePopup.id === popup);
      newPopups.splice(this.state.activePopups.indexOf(foundPopup), 1);
    }
    else {
      newPopups.push(popup);
    }    
    this.setState({ activePopups: newPopups });
  }
  closePopups() {
    this.setState({ activePopups: [] });
  }
  closeItemRoll(){
    if (this.state.currentItemRoll != null){
      this.setState({ currentItemRoll: null });
    }
  }
  toggleHeaderDropdown(type) {
    let typeToSet = type;
    if (type === this.state.headerDropdown) {
      typeToSet = 'none';
    }
    this.setState({ headerDropdown: typeToSet });
  }
  toggleTwitchView() {
    this.setState({
      isTwitchVideoOpen: !this.state.isTwitchVideoOpen
    });
  }
  renderHeaderDropdown() {
    switch (this.state.headerDropdown) {
      case 'backpack':
        return <BackpackWindow toggle={this.toggleHeaderDropdown.bind(this)} sellPrices={this.state.prices.SellPrices}/>;
      case 'golden-banana-upgrades':
        return <GoldenBananaUpgradesWindow upgrades={this.state.user.Upgrades}/>;
      case 'sub-point-upgrades':
        return <SubPointUpgradesWindow upgrades={this.state.subPointUpgrades}/>;
      default:
        return;
    }
  }
  renderTwitchView() {
    if (this.state.isTwitchVideoOpen) {
      return <TwitchView toggle={this.toggleTwitchView.bind(this)}/>
    }
  }
  render(){
    if (this.state.isConnectionClosed && !this.state.isConnectionError) {
      return (
        <div className='centerContainer'>
          <span id='disconnectedText'>DISCONNECTED</span>
          <p id='tryRefreshingText'>Try refreshing?</p>
        </div>
      );
    }
    if (this.state.isConnectionError) {
      return (
        <div className='centerContainer'>
          <span id='disconnectedText'>SERVER OFFLINE</span>
          <p id='tryRefreshingText'>Try refreshing?</p>
        </div>
      );
    }
    if (!this.state.isConnectedToWS) {
      return (
        <div className='centerContainer'>
          <span id='connectingText'>CONNECTING...</span>
        </div>
      );
    }
    if (!this.state.isLoggedIn) {
      return (
        <div className="App">     
          <TwitchLoginButton isEnabled={this.state.isConnectedToWS} username={null}/>
        </div>
      );
    }
    if (this.state.prices == null) {
      return (
        <div className='centerContainer'>
          <span id='connectingText'>WAITING FOR DATA...</span>
        </div>
      );
    }
    if (this.state.isLoggedIn){
      return (
        <div className="App">
          <XpBar newXp={this.state.newXp}/>
          <div id='topBar'>
            <div className={'topBarButtonsContainer' + (this.state.isTwitchVideoOpen && !isMobile ? ' vertical' : ' horizontal' )}>
              <NavButtons user={this.state.user}/>
              <BackpackOpener toggle={this.toggleHeaderDropdown.bind(this)}/>
              <SubPointButton toggle={this.toggleHeaderDropdown.bind(this)} subPointAmount={this.state.user.Currencies.SubPoints}/>
              <GoldenBananaButton toggle={this.toggleHeaderDropdown.bind(this)} goldenBananaAmount={this.state.user.Currencies.GoldenBananas}/>
              {this.state.isTwitchVideoOpen ? undefined : TwitchViewButton({twitchViewActive: this.state.isTwitchVideoOpen, click: this.toggleTwitchView.bind(this)})}
            </div>
            {this.renderTwitchView()}
          </div>
          <div id='mainContentContainer'>
            {this.renderHeaderDropdown()}
            <RewardsContainer rarity='common' prices={this.state.prices} currencyDelta={this.state.currencyDeltas.bananas} deltaKey={this.state.deltaKey}/>
            <RewardsContainer rarity='uncommon' prices={this.state.prices} currencyDelta={this.state.currencyDeltas.tokens} deltaKey={this.state.deltaKey}/>
            <RewardsContainer rarity='rare' prices={this.state.prices} currencyDelta={this.state.currencyDeltas.cash} deltaKey={this.state.deltaKey}/>
            <RewardsContainer rarity='epic' prices={this.state.prices} currencyDelta={this.state.currencyDeltas.bits} deltaKey={this.state.deltaKey}/>
          </div>
          <PopupsContainer activePopups={this.state.activePopups} closePopups={this.closePopups.bind(this)}/>
          <ItemRollPopup itemRoll={this.state.currentItemRoll}/>
        </div>
      );
    }
  }
}

export default App;
