const maxCommandsPerInterval = 15;
class SiWebSocket{

    constructor(){
        console.log("Starting connection...");
        this.webSocket = new WebSocket("wss://interactor.yoyoyopo5.com:8443/control");
        this.webSocket.onopen = () => this.init();
        this.commandsSentInInterval = 0;
        setInterval(() => { this.commandsSentInInterval = 0 }, 1000);
    }

    init() {
        this.sendCommand({ type: 'handshake' });
        setInterval(() => {
            this.sendCommand({ type: 'keep-alive' })
        }, 60000);
    }

    checkAuth() {
        if (window.localStorage.getItem('twitch_access_token') != null){ //User might be logged in
            let authCommand = {
              type: 'auth',
              token: JSON.parse(window.localStorage.getItem('twitch_access_token')),
              claims: JSON.parse(window.localStorage.getItem('twitch_claims'))
            }
            this.sendCommand(authCommand);
        }
    }

    sendCommand(command){
        if (this.commandsSentInInterval >= maxCommandsPerInterval) { return; }
        let stringCommand = JSON.stringify(command);
        this.webSocket.send(stringCommand);
        this.commandsSentInInterval++;
        return stringCommand;
    }
    
    sendThrow(name = null){
        let command = {
            type: "throwable",
            name: name
        };
        this.sendCommand(command);
        return JSON.stringify(command);
    }

    sendSound(name = null, charge = 0){
        let command = {
            type: "sound",
            charge: charge,
            name: name
        };
        console.log(command);
        this.sendCommand(command);
    }

    sendScene(name = null){
        let command = {
            type: "scene",
            name: name
        };
        console.log(command);
        this.sendCommand(command);
    }

    sendFirework() {
        let command = {
            type: 'firework',
            thrust: Math.random(),
            angle: Math.random(),
            startAt: Math.random(),
            explodeAt: Math.random()
        }
        this.sendCommand(command);
    }

    sendGs(name = null) {
        let command = {
            type: 'greenscreen',
            name: name
        }
        this.sendCommand(command);
    }

    sendVideo(name = null) {
        let command = {
            type: 'video',
            name: name
        }
        this.sendCommand(command);
    }

    sendKey(key) {
        let command = {
            type: 'key-press',
            key: key
        }
        this.sendCommand(command);
    }

    sendYoutube(url) {
        let command = {
            type: 'youtube',
            url: url
        }
        this.sendCommand(command);
    }

    sendRoll(itemType) {
        let command = {
            type: 'roll-item',
            itemType: itemType
        }
        this.sendCommand(command);
    }

    sendSell(itemName){
        let command = {
            type: "sell-item",
            itemName: itemName
        }
        this.sendCommand(command);
    }
}

export default SiWebSocket;

